import { ClipboardModule } from '@angular/cdk/clipboard';
import { OverlayModule } from '@angular/cdk/overlay';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkTreeModule } from '@angular/cdk/tree';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { AngularCesiumModule, AngularCesiumWidgetsModule, PolygonsEditorService, PolylinesEditorService } from 'angular-cesium';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { NgChartsModule } from 'ng2-charts';
import { ColorTwitterModule } from 'ngx-color/twitter';
import { NgxFileDropModule } from 'ngx-file-drop';
import { ArrowPopupLayoutComponent } from './arrow-popup-layout/arrow-popup-layout.component';
import { ColorPaletteSelectComponent } from './color-palette-select/color-palette-select.component';
import { ColorPaletteComponent } from './color-palette-select/color-palette/color-palette.component';
import { ColorPickerComponent } from './color-picker/color-picker.component';
import { ColorSliderComponent } from './color-slider/color-slider.component';
import { CompassComponent } from './compass/compass.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { CoordinateSystemModifierComponent } from './coordinate-system-modifier/coordinate-system-modifier.component';
import { CoordinateSystemSelectComponent } from './coordinate-system-select/coordinate-system-select.component';
import { DetailsBoxComponent } from './details-box/details-box.component';
import { DialogHintMessageComponent } from './dialog-hint-message/dialog-hint-message.component';
import { DialogLayoutComponent } from './dialog-layout/dialog-layout.component';
import { DialogTabGroupComponent } from './dialog-layout/dialog-tab-group/dialog-tab-group.component';
import { DialogTabComponent } from './dialog-layout/dialog-tab-group/dialog-tab/dialog-tab.component';
import { DisableByAccessLevelDirective } from './directives/disable-by-access-level.directive';
import { MaxLengthCounterDirective } from './directives/max-length-counter.directive';
import { RefDirective } from './directives/ref.directive';
import { DragAndDropContainerComponent } from './drag-and-drop-container/drag-and-drop-container.component';
import { DragAndDropImageControlComponent } from './drag-and-drop-image-control/drag-and-drop-image-control.component';
import { FeatureHintComponent } from './feature-hint/feature-hint.component';
import { FieldErrorComponent } from './field-error/field-error.component';
import { FullscreenLoaderComponent } from './fullscreen-loader/fullscreen-loader.component';
import { GridReportCellLegendComponent } from './grid-report-cell-legend/grid-report-cell-legend.component';
import { HintTagComponent } from './hint-tag/hint-tag.component';
import { IconSnackbarComponent } from './icon-snackbar/icon-snackbar.component';
import { IframeDialogComponent } from './iframe-dialog/iframe-dialog.component';
import { IssueBannerComponent } from './issue-banner/issue-banner.component';
import { IssuesDialogComponent } from './issues-dialog/issues-dialog.component';
import { IssuesSummaryComponent } from './issues-summary/issues-summary.component';
import { LimitReachedPopupComponent } from './limit-reached-popup/limit-reached-popup.component';
import { LimitReachedComponent } from './limit-reached-popup/limit-reached/limit-reached.component';
import { FillingSquareLoaderComponent } from './loader/filling-square-loader/filling-square-loader.component';
import { FoldingCubeLoaderComponent } from './loader/folding-cube-loader/folding-cube-loader.component';
import { LoaderComponent } from './loader/loader.component';
import { LoadingModalComponent } from './loading-modal/loading-modal.component';
import { MapCompassComponent } from './map-compass/map-compass.component';
import { GroupLayerDetailsComponent } from './map-modal-layout/group-layer-details/group-layer-details.component';
import { GroupsLayersManagerComponent } from './map-modal-layout/groups-layers-manager/groups-layers-manager.component';
import { GroupsLayersSelectComponent } from './map-modal-layout/groups-layers-select/groups-layers-select.component';
import { MapModalLayoutComponent } from './map-modal-layout/map-modal-layout.component';
import { IsCustomElevationPipe } from './map-modal-layout/map-modal-subtitle/map-modal-subtitle-base-surface-field/is-custom-elevation.pipe';
import { MapModalSubtitleBaseSurfaceFieldComponent } from './map-modal-layout/map-modal-subtitle/map-modal-subtitle-base-surface-field/map-modal-subtitle-base-surface-field.component';
import { MapModalSubtitleCustomBaseSurfaceDialogComponent } from './map-modal-layout/map-modal-subtitle/map-modal-subtitle-base-surface-field/map-modal-subtitle-custom-base-surface-dialog/map-modal-subtitle-custom-base-surface-dialog.component';
import { MapModalSubtitleComponent } from './map-modal-layout/map-modal-subtitle/map-modal-subtitle.component';
import { MapModalTitleComponent } from './map-modal-layout/map-modal-title/map-modal-title.component';
import { MapPlonterComponent } from './map-plonter/map-plonter.component';
import { MapScaleBarComponent } from './map-scale-bar/map-scale-bar.component';
import { MapStyleButtonComponent } from './map-style-button/map-style-button.component';
import { MapToolBtnComponent } from './map-tool-btn/map-tool-btn.component';
import { MeasurementChartComponent } from './measurement-chart/measurement-chart.component';
import { AccountDialogComponent } from './navbar/account-dialog/account-dialog.component';
import { AnnouncementMenuComponent } from './navbar/announcement-menu/announcement-menu.component';
import { AnnouncementsDialogComponent } from './navbar/announcement-menu/announcements-dialog/announcements-dialog.component';
import { NavbarComponent } from './navbar/navbar.component';
import { NotificationsMenuComponent } from './navbar/notifications-menu/notifications-menu.component';
import { ProcessingHistoryComponent } from './navbar/processing-history/processing-history.component';
import { OpacitySliderComponent } from './opacity-slider/opacity-slider.component';
import { ActivityMeasureIconPipe } from './pipes/activity-measure-icon.pipe';
import { DrawingIconPipe } from './pipes/drawing-icon.pipe';
import { FilterHighlightPipe } from './pipes/filter-highlight.pipe';
import { FormatDateNamePipe } from './pipes/format-date-name.pipe';
import { FormatDateNumeralPipe } from './pipes/format-date-numeral.pipe';
import { FormatUserNamePipe } from './pipes/format-user-name.pipe';
import { IsDefinedPipe } from './pipes/is-defined.pipe';
import { RoundAndFormatNumberPipe } from './pipes/round-and-format-number.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { ReferenceSurfaceTipDialogComponent } from './reference-surface-tip-dialog/reference-surface-tip-dialog.component';
import { SearchFieldComponent } from './search-field/search-field.component';
import { ShareResourceDialogComponent } from './share-resource-dialog/share-resource-dialog.component';
import { SingleImagePreviewComponent } from './single-image-preview/single-image-preview.component';
import { SpinnerProgressComponent } from './spinner-progress/spinner-progress.component';
import { TableHeaderInfoTooltipComponent } from './table-header-info-tooltip/table-header-info-tooltip.component';
import { ToggleButtonGroupComponent } from './toggle-button-group/toggle-button-group.component';
import { ToggleButtonComponent } from './toggle-button-group/toggle-button/toggle-button.component';
import { ToggleReadItemsMenuComponent } from './toggle-read-items-menu/toggle-read-items-menu.component';
import { UnderlineTooltipComponent } from './underline-tooltip/underline-tooltip.component';
import { UploadingProgressComponent } from './uploading-progress/uploading-progress.component';
import { NameListComponent } from './users-and-teams-dialog/name-list/name-list.component';
import { TeamDialogComponent } from './users-and-teams-dialog/team-dialog/team-dialog.component';
import { UpdateUsersDialogComponent } from './users-and-teams-dialog/update-users-dialog/update-users-dialog.component';
import { PermissionLevelComponent } from './users-and-teams-dialog/user-dialog/permission-level/permission-level.component';
import { UserDialogComponent } from './users-and-teams-dialog/user-dialog/user-dialog.component';
import { UsersAndTeamsDialogComponent } from './users-and-teams-dialog/users-and-teams-dialog.component';
import { VersionPageComponent } from './version-page/version-page.component';
import { ZoomImageComponent } from './zoom-image/zoom-image.component';

@NgModule({
  declarations: [
    NavbarComponent,
    DialogLayoutComponent,
    LoaderComponent,
    MapModalLayoutComponent,
    MapModalTitleComponent,
    MeasurementChartComponent,
    MapModalSubtitleComponent,
    ToggleButtonGroupComponent,
    ToggleButtonComponent,
    GroupsLayersManagerComponent,
    GroupLayerDetailsComponent,
    ColorPickerComponent,
    ArrowPopupLayoutComponent,
    ProcessingHistoryComponent,
    MapToolBtnComponent,
    MapStyleButtonComponent,
    ConfirmationDialogComponent,
    LoadingModalComponent,
    VersionPageComponent,
    NotificationsMenuComponent,
    LimitReachedPopupComponent,
    LimitReachedComponent,
    FieldErrorComponent,
    CoordinateSystemSelectComponent,
    IframeDialogComponent,
    OpacitySliderComponent,
    UsersAndTeamsDialogComponent,
    TeamDialogComponent,
    NameListComponent,
    UserDialogComponent,
    DialogTabGroupComponent,
    DialogTabComponent,
    PermissionLevelComponent,
    ColorSliderComponent,
    AccountDialogComponent,
    FoldingCubeLoaderComponent,
    FillingSquareLoaderComponent,
    DisableByAccessLevelDirective,
    DialogHintMessageComponent,
    MaxLengthCounterDirective,
    IssueBannerComponent,
    CoordinateSystemModifierComponent,
    UploadingProgressComponent,
    IssuesSummaryComponent,
    GroupsLayersSelectComponent,
    DragAndDropContainerComponent,
    MapModalSubtitleBaseSurfaceFieldComponent,
    MapModalSubtitleCustomBaseSurfaceDialogComponent,
    TableHeaderInfoTooltipComponent,
    DetailsBoxComponent,
    IsCustomElevationPipe,
    IsDefinedPipe,
    FormatDateNamePipe,
    FormatDateNumeralPipe,
    DragAndDropImageControlComponent,
    SingleImagePreviewComponent,
    FormatUserNamePipe,
    RoundAndFormatNumberPipe,
    DragAndDropImageControlComponent,
    ColorPaletteSelectComponent,
    ColorPaletteComponent,
    IconSnackbarComponent,
    GridReportCellLegendComponent,
    FormatDateNumeralPipe,
    ReferenceSurfaceTipDialogComponent,
    IssuesDialogComponent,
    UpdateUsersDialogComponent,
    ActivityMeasureIconPipe,
    ToggleReadItemsMenuComponent,
    AnnouncementMenuComponent,
    AnnouncementsDialogComponent,
    DrawingIconPipe,
    SpinnerProgressComponent,
    SearchFieldComponent,
    FullscreenLoaderComponent,
    ShareResourceDialogComponent,
    ZoomImageComponent,
    UnderlineTooltipComponent,
    HintTagComponent,
    FilterHighlightPipe,
    SafeHtmlPipe,
    CompassComponent,
    MapCompassComponent,
    FeatureHintComponent,
    MapPlonterComponent,
    RefDirective,
    MapScaleBarComponent
  ],
  imports: [
    AngularCesiumModule,
    AngularCesiumWidgetsModule,
    MatButtonModule,
    MatIconModule,
    CommonModule,
    OverlayModule,
    RouterModule,
    MatDialogModule,
    NgSelectModule,
    MatMomentDateModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    MatRadioModule,
    MatProgressBarModule,
    MatRippleModule,
    MatAutocompleteModule,
    MatTableModule,
    MatButtonToggleModule,
    MatSelectModule,
    MatMenuModule,
    MatDividerModule,
    MatInputModule,
    MatCardModule,
    NgChartsModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    MatCheckboxModule,
    ColorTwitterModule,
    MatBadgeModule,
    NgClickOutsideDirective,
    MatSliderModule,
    MatSortModule,
    FormsModule,
    MatTabsModule,
    MatTooltipModule,
    ClipboardModule,
    NgxFileDropModule,
    MatListModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    CdkTreeModule,
    ScrollingModule,
    MatTreeModule,
    MatChipsModule
  ],
  exports: [
    MatSnackBarModule,
    NavbarComponent,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    NgSelectModule,
    MatDatepickerModule,
    DialogLayoutComponent,
    MatSlideToggleModule,
    MatRadioModule,
    MatProgressBarModule,
    LoaderComponent,
    MatRippleModule,
    MatAutocompleteModule,
    MatTableModule,
    MatButtonToggleModule,
    MapModalLayoutComponent,
    MatSelectModule,
    MatMenuModule,
    MapModalTitleComponent,
    MapModalSubtitleComponent,
    MatDividerModule,
    MatInputModule,
    MatCardModule,
    ClipboardModule,
    MeasurementChartComponent,
    ToggleButtonGroupComponent,
    ToggleButtonComponent,
    MatCheckboxModule,
    ColorTwitterModule,
    MatBadgeModule,
    NgClickOutsideDirective,
    MatSliderModule,
    ArrowPopupLayoutComponent,
    MatSortModule,
    MatTabsModule,
    MapToolBtnComponent,
    MapStyleButtonComponent,
    ConfirmationDialogComponent,
    LoadingModalComponent,
    LimitReachedComponent,
    FieldErrorComponent,
    MatTooltipModule,
    CoordinateSystemSelectComponent,
    OpacitySliderComponent,
    NameListComponent,
    DialogTabGroupComponent,
    DialogTabComponent,
    ColorSliderComponent,
    DisableByAccessLevelDirective,
    DialogHintMessageComponent,
    MaxLengthCounterDirective,
    IssueBannerComponent,
    IssuesSummaryComponent,
    NgxFileDropModule,
    CoordinateSystemModifierComponent,
    UploadingProgressComponent,
    GroupsLayersSelectComponent,
    DragAndDropContainerComponent,
    MapModalSubtitleBaseSurfaceFieldComponent,
    TableHeaderInfoTooltipComponent,
    DetailsBoxComponent,
    IsDefinedPipe,
    FormatDateNamePipe,
    FormatDateNumeralPipe,
    FormatUserNamePipe,
    RoundAndFormatNumberPipe,
    NgChartsModule,
    DragAndDropImageControlComponent,
    SingleImagePreviewComponent,
    ColorPaletteSelectComponent,
    ColorPickerComponent,
    GridReportCellLegendComponent,
    FormatDateNumeralPipe,
    ReferenceSurfaceTipDialogComponent,
    MatListModule,
    IssuesDialogComponent,
    ActivityMeasureIconPipe,
    DrawingIconPipe,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    SpinnerProgressComponent,
    MatTreeModule,
    CdkTreeModule,
    OverlayModule,
    SearchFieldComponent,
    FullscreenLoaderComponent,
    ShareResourceDialogComponent,
    ScrollingModule,
    MatChipsModule,
    ZoomImageComponent,
    UnderlineTooltipComponent,
    HintTagComponent,
    FilterHighlightPipe,
    SafeHtmlPipe,
    CompassComponent,
    MapCompassComponent,
    FeatureHintComponent,
    MapPlonterComponent,
    RefDirective,
    MapScaleBarComponent
  ],
  providers: [
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 3000 } },
    { provide: MAT_DIALOG_DATA, useValue: [] },
    PolygonsEditorService,
    PolylinesEditorService
  ]
})
export class SharedModule {}
