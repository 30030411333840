export function getViewerUrls(credentialsUrl: string, taskId: string) {
  const baseTaskUrl = `${credentialsUrl}/${taskId}`;

  return {
    terrain: `${baseTaskUrl}/terrain`,
    terrainComplete: `${baseTaskUrl}/dsmterrain`,
    tiles3d: `${baseTaskUrl}/texture/tileset.json`,
    imagery: `${baseTaskUrl}/orthophoto`,
    pointCloud: `${baseTaskUrl}/pointcloud/tileset.json`
  };
}
