import { registerLocaleData } from '@angular/common';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { isDefined } from '../utils/general';
import { roundTo } from '../utils/math';

const DEFAULT_LOCALE = 'en-GB';
const MAIN_LOCALE_IDS = [
  'en-US',
  'pt-BR',
  'he-IL',
  'ja-JP',
  'lb-LU',
  'de-DE',
  'es-ES',
  'fr-FR',
  'naq-NA',
  'ro-RO',
  'nb-NO',
  'nn-NO',
  'se-NO',
  'se-SE',
  'cs-CZ',
  'bg-BG'
];

function getUserLocale() {
  let locale: string;
  const navigator = window.navigator as any;
  if (navigator.languages) {
    locale = navigator.languages[0];
  } else {
    locale = navigator.userLanguage || navigator.language;
  }

  // Fix main locale ids
  if (MAIN_LOCALE_IDS.includes(locale)) {
    locale = locale.split('-')[0];
  }

  return locale;
}

class LocaleNumberParser {
  private groupRegExp: RegExp;
  private decimalRegExp: RegExp;
  private numeralRegExp: RegExp;
  private numMapper: (d: string) => string;

  constructor(locale: string) {
    const format = new Intl.NumberFormat(locale);
    const parts = format.formatToParts(12345.6);
    const numerals = Array.from({ length: 10 }).map((_, i) => format.format(i));
    const index = new Map(numerals.map((d, i) => [d, i]));
    this.groupRegExp = new RegExp(`[${parts.find(d => d.type === 'group').value}]`, 'g');
    this.decimalRegExp = new RegExp(`[${parts.find(d => d.type === 'decimal').value}]`);
    this.numeralRegExp = new RegExp(`[${numerals.join('')}]`, 'g');
    this.numMapper = (d: string) => String(index.get(d));
  }

  parse(numberStr: string) {
    if (!numberStr || typeof numberStr !== 'string') {
      return NaN;
    }

    const parsedNumber = numberStr
      .trim()
      .replace(this.groupRegExp, '')
      .replace(this.decimalRegExp, '.')
      .replace(this.numeralRegExp, this.numMapper);

    return parsedNumber ? +parsedNumber : NaN;
  }
}

@Injectable({
  providedIn: 'root'
})
export class LocaleService {
  private userLocale: string;
  private numberParser: LocaleNumberParser;

  constructor() {
    this.userLocale = getUserLocale() ?? DEFAULT_LOCALE;
    this.numberParser = new LocaleNumberParser(this.userLocale);
  }

  registerLocale = async () => {
    try {
      await this.registerLocaleInAngular(this.userLocale);
    } catch (error) {
      console.error(`Error registering locale "${this.userLocale}"`, error);
      this.userLocale = DEFAULT_LOCALE;
    }

    this.registerLocaleInMoment(this.userLocale);
  };

  private async registerLocaleInAngular(locale: string) {
    // Import specific the specific locale data. Added webpack directives to only have it load specific locals that were in use in DatuBIM in 2024
    const [localeModule, localeExtraModule] = await Promise.all([
      import(
        /* webpackInclude: /(en|he|ar-PS|ar-IL|es-US|de-BE|en-BE|fr-BE|nl-BE|ja|lb|en-CA|fr-CA|es-CL|de|es|en-GB|en-AU|pt|pt-PT|fr|af-NA|en-NA|naq|en-IN|ro|pt-AO|ln-AO|af-ZA|en-ZA|zu-ZA|es-MX|nb|nb-SJ|nn|se|cs|bg)\.mjs/ */
        /* webpackMode: "lazy-once" */
        /* webpackChunkName: "i18n-base" */
        `/node_modules/@angular/common/locales/${locale}`
      ),
      import(
        /* webpackInclude: /(en|he|ar-PS|ar-IL|es-US|de-BE|en-BE|fr-BE|nl-BE|ja|lb|en-CA|fr-CA|es-CL|de|es|en-GB|en-AU|pt|pt-PT|fr|af-NA|en-NA|naq|en-IN|ro|pt-AO|ln-AO|af-ZA|en-ZA|zu-ZA|es-MX|nb|nb-SJ|nn|se|cs|bg)\.mjs/ */
        /* webpackMode: "lazy-once" */
        /* webpackChunkName: "i18n-extra" */
        `/node_modules/@angular/common/locales/extra/${locale}`
      )
    ]);
    registerLocaleData(localeModule.default, locale, localeExtraModule.default);
  }

  private registerLocaleInMoment(locale: string) {
    moment.locale(locale);

    // Fix moment issue with some locales
    const momentLocaleConfig = moment.localeData();
    momentLocaleConfig.longDateFormat('lll');
    momentLocaleConfig.longDateFormat('ll');
    momentLocaleConfig.longDateFormat('l');
  }

  get locale() {
    return this.userLocale;
  }

  formatNumber(n: number, options?: Intl.NumberFormatOptions) {
    if (!isDefined(n)) {
      return '';
    }

    return n.toLocaleString(this.locale, options);
  }

  parseNumber(numberStr: string) {
    return this.numberParser.parse(numberStr);
  }

  formatAndRoundNumber(n: number, accuracy = 2, showFullFraction = true) {
    if (!isDefined(n)) {
      return '';
    }

    return this.formatNumber(roundTo(n, accuracy), { minimumFractionDigits: showFullFraction ? accuracy : undefined });
  }

  getDateFormat() {
    return moment.localeData().longDateFormat('L');
  }

  formatDateName({ date, withTime }: { date: Date | string; withTime?: boolean }) {
    return moment(date).format(withTime ? 'lll' : 'll');
  }

  formatDateNumeral({ date, shortYear, withTime }: { date: Date | string; shortYear?: boolean; withTime?: boolean }) {
    if (!isDefined(date)) {
      return null;
    }
    const d = moment(date);
    const dateFormat = shortYear ? this.getDateFormat().replace('YYYY', 'YY') : 'L';
    const result = d.format(dateFormat);
    const time = moment.utc(date).local().format('LT');
    return withTime ? `${result} ${time}` : result;
  }

  formatShortDate({ date }: { date: Date | string }) {
    const now = moment();
    const d = moment(date);
    if (d.isAfter(now.subtract(2, 'days'))) {
      return d.calendar();
    } else if (d.isAfter(now.startOf('year'))) {
      const dateFormat = this.getDateFormat().replace('YYYY', '');
      let result = d.format(dateFormat);
      // after replace 'YYYY' to '' dateFormat contains one unnecessary delimeter symbol in the begin or in the end
      // should be deleted
      const firstSymbolToNum = parseInt(result[0]);
      const isFirstSymbolNum = !!firstSymbolToNum || firstSymbolToNum === 0;
      result = !isFirstSymbolNum ? result.substr(1, 5) : result.substr(0, 5);
      return `${result} ${d.format('LT')}`;
    } else {
      return `${d.format('L')} ${d.format('LT')}`;
    }
  }
}
