import { Injectable } from '@angular/core';
import { Query, QueryEntity } from '@datorama/akita';
import { flatten } from 'lodash';
import { combineLatest, map } from 'rxjs';
import { GridReportType, ReportEntity, ReportState, ReportType } from './detailed-site-reports.model';
import { DetailedSiteReportsState, DetailedSiteReportsStore } from './detailed-site-reports.store';

@Injectable({ providedIn: 'root' })
export class DetailedSiteReportsQuery extends Query<DetailedSiteReportsState> {
  crossSectionVolumeReportsQuery = new QueryEntity(this.store.crossSectionVolumeReports);
  crossSectionVolumeReports$ = this.crossSectionVolumeReportsQuery.selectAll();

  crossSectionProgressReportsQuery = new QueryEntity(this.store.crossSectionProgressReports);
  crossSectionProgressReports$ = this.crossSectionProgressReportsQuery.selectAll();

  surfaceGradeCheckingReportsQuery = new QueryEntity(this.store.surfaceGradeCheckingReports);
  surfaceGradeCheckingReports$ = this.surfaceGradeCheckingReportsQuery.selectAll();

  roadGradeCheckingReportsQuery = new QueryEntity(this.store.roadGradeCheckingReports);
  roadGradeCheckingReports$ = this.roadGradeCheckingReportsQuery.selectAll();

  gridReportsQuery = new QueryEntity(this.store.gridReports);
  volumeGridReports$ = this.gridReportsQuery.selectAll({ filterBy: report => report.gridReportType === GridReportType.VGHR });
  elevationGridReports$ = this.gridReportsQuery.selectAll({ filterBy: report => report.gridReportType === GridReportType.EGHR });

  waterFlowReportsQuery = new QueryEntity(this.store.waterFlowReports);
  waterFlowReports$ = this.waterFlowReportsQuery.selectAll();

  reportsByType$ = combineLatest({
    [ReportType.CROSS_SECTION_VOLUME]: this.crossSectionVolumeReports$,
    [ReportType.CROSS_SECTION_PROGRESS]: this.crossSectionProgressReports$,
    [ReportType.VOLUME_GRID_HEATMAP]: this.volumeGridReports$,
    [ReportType.ELEVATION_GRID_HEATMAP]: this.elevationGridReports$,
    [ReportType.SURFACE_GRADE_CHECKING]: this.surfaceGradeCheckingReports$,
    [ReportType.ROAD_GRADE_CHECKING]: this.roadGradeCheckingReports$,
    [ReportType.WATER_FLOW]: this.waterFlowReports$
  });
  reports$ = this.reportsByType$.pipe(map(reportsByType => flatten<ReportEntity>(Object.values(reportsByType))));
  readyReports$ = this.reports$.pipe(map(reports => reports.filter(report => report.state === ReportState.READY)));

  constructor(protected store: DetailedSiteReportsStore) {
    super(store);
  }

  getReportById(id: string) {
    return (
      this.crossSectionVolumeReportsQuery.getEntity(id) || this.gridReportsQuery.getEntity(id) || this.waterFlowReportsQuery.getEntity(id)
    );
  }

  getAllCrossSectionVolumeReports() {
    return this.crossSectionVolumeReportsQuery.getAll();
  }

  getAllCrossSectionProgressReports() {
    return this.crossSectionProgressReportsQuery.getAll();
  }

  getAllSurfaceGradeCheckingReports() {
    return this.surfaceGradeCheckingReportsQuery.getAll();
  }

  getAllRoadGradeCheckingReports() {
    return this.roadGradeCheckingReportsQuery.getAll();
  }

  getAllGridReports() {
    return this.gridReportsQuery.getAll();
  }

  getAllWaterFlowReports() {
    return this.waterFlowReportsQuery.getAll();
  }
}
