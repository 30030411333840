import { Injectable } from '@angular/core';
import { Feature, length, Point, polygon } from '@turf/turf';
import { GeoUtils } from '../../../shared/utils/geo';
import { PolygonCalcService, PrecalcData } from '../../services/calc-services';
import { Terrain } from '../../services/terrain-provider.service';
import { TerrainSamplingService } from '../../services/terrain-sampling.service';
import { MapEntity, TaskOrDesignValues } from '../../state/detailed-site-entities/detailed-site-entities.model';
import { DetailedSiteQuery } from '../../state/detailed-site.query';
import { FIELD_MAPPING } from '../../state/detailed-site.utils';
import { DetailedSiteService } from '../../state/detailed-site.service';

@Injectable({
  providedIn: 'root'
})
export class AreaCalcService extends PolygonCalcService {
  constructor(
    protected siteQuery: DetailedSiteQuery,
    protected terrainSampling: TerrainSamplingService,
    private siteService: DetailedSiteService
  ) {
    super(siteQuery, terrainSampling);
  }

  async calcResults(
    entity: MapEntity,
    siteId: string,
    modelsData: { id: string; type: 'TASK' | 'DESIGN' }[],
    precalcData: PrecalcData
  ): Promise<{ calcResult: TaskOrDesignValues[] }> {
    const { cellSide, feature, pointGridFeatureCollection } = precalcData;

    let calcResult: TaskOrDesignValues[] = entity?.calcResult ?? [];
    for (let i = 0; i < modelsData.length; i++) {
      const modelData = modelsData[i];
      const modelTerrain = await this.siteService.getTerrainProvider(siteId, modelData.id, modelData.type, entity.sourceModel);

      const cartPositions = entity.positions.map(p => GeoUtils.cartesian3ToDeg(p));
      const polygonPoints = cartPositions.map(latlon => [latlon.longitude, latlon.latitude]);
      const polygonFeature = polygon([[...polygonPoints, polygonPoints[0]]]);

      const horizontalArea = this.calcHorizontalArea(entity.positions);
      const horizontalPerimeter = length(polygonFeature, { units: 'meters' });
      const [surfacePerimeter, surfaceArea] =
        modelTerrain.type === 'FLAT'
          ? [horizontalPerimeter, horizontalArea]
          : await Promise.all([
              this.calcSurfacePerimeter(pointGridFeatureCollection.features, modelTerrain),
              this.calcSurfaceArea(entity.positions, modelTerrain)
            ]);

      calcResult = [
        ...calcResult,
        {
          id: modelData.id,
          type: modelData.type,
          values: [
            { ...FIELD_MAPPING.surfaceArea, value: surfaceArea },
            { ...FIELD_MAPPING.horizontalArea, value: horizontalArea },
            { ...FIELD_MAPPING.areaSurfacePerimeter, value: surfacePerimeter },
            { ...FIELD_MAPPING.areaHorizontalPerimeter, value: horizontalPerimeter }
          ]
        }
      ];
    }

    return { calcResult };
  }

  private async calcSurfacePerimeter(samplePoints: Feature<Point>[], terrain: Terrain) {
    // Sample each position to get accurate height
    const coordinates = samplePoints.map(point =>
      Cesium.Cartesian3.fromDegrees(point.geometry.coordinates[0], point.geometry.coordinates[1])
    );
    const coordinatesWithHeight = await this.terrainSampling.sampleTerrain(coordinates, terrain);

    // Calculate distance between each two points and return sum
    let distance = 0;
    for (let i = 0; i < coordinatesWithHeight.length - 1; i++) {
      distance += GeoUtils.distance(coordinatesWithHeight[i], coordinatesWithHeight[i + 1]);
    }

    return distance;
  }
}
