/**
 * Measurements Management Service
 * APIs document (version: 2.1.6)
 *
 * OpenAPI spec version: 2.1.6
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CoordinateBlock } from './coordinateBlock';
import { CoordinateModel } from './coordinateModel';
import { DrawingStyleProps } from './drawingStyleProps';
import { GetCreatedByResponse } from './getCreatedByResponse';

export interface GetDrawingResponse {
  coordinates?: Array<CoordinateModel>;
  createdBy?: GetCreatedByResponse;
  creationTime?: Date;
  hiddenLabel?: boolean;
  id?: string;
  lastModifiedTime?: Date;
  name?: string;
  positions?: Array<CoordinateBlock>;
  style?: DrawingStyleProps;
  taskId?: string;
  type?: GetDrawingResponse.TypeEnum;
}
export namespace GetDrawingResponse {
  export type TypeEnum = 'ARROW' | 'POLYLINE' | 'POLYGON' | 'RECTANGLE' | 'ELLIPSE' | 'TEXT';
  export const TypeEnum = {
    ARROW: 'ARROW' as TypeEnum,
    POLYLINE: 'POLYLINE' as TypeEnum,
    POLYGON: 'POLYGON' as TypeEnum,
    RECTANGLE: 'RECTANGLE' as TypeEnum,
    ELLIPSE: 'ELLIPSE' as TypeEnum,
    TEXT: 'TEXT' as TypeEnum
  };
}
