import { Injectable } from '@angular/core';
import { Feature, FeatureCollection, Geometry } from '@turf/turf';
import { MapsManagerService } from 'angular-cesium';
import {
  Cesium3DTile,
  Cesium3DTileFeature,
  Cesium3DTileset,
  EntityCollection,
  ImageryLayer,
  ImageryLayerCollection,
  OrientedBoundingBox,
  PostProcessStage,
  PrimitiveCollection,
  TileProviderError
} from 'cesium';
import { groupBy, partition } from 'lodash';
import { environment } from '../../../../environments/environment';
import { ViewerResourcesService } from '../../../shared/services/viewer-resources.service';
import { applyOffsetTo3DTile, DEFAULT_MAX_DETAIL_LEVEL, TILES_3D_DEFAULT_PROPERTIES } from '../../../shared/utils/cesium-common';
import { isDefined } from '../../../shared/utils/general';
import { GeoUtils } from '../../../shared/utils/geo';
import { DistanceUnitsEnum } from '../../../shared/utils/unit-conversion';
import { VectorTilesImageryProvider } from '../../../shared/vector-tiles-provider/vector-tiles-imagery-provider';
import { DetailedSiteQuery } from '../detailed-site.query';
import { DesignsGeoJsonService } from './designs-geojson.service';
import { Design, DesignLayerProperty, DesignLayerPropertyType, getDesignFeatureId, RoadDesign } from './detailed-site-designs.model';
import { DetailedSiteDesignsQuery } from './detailed-site-designs.query';

const translated2D = $localize`:@@detailedSite.designMapManager.2D:2D`;
const translated3D = $localize`:@@detailedSite.designMapManager.3D:3D`;
export const MAX_GEOJSON_SIZE = 1 * 1024 * 1024 * 1024; // 1 GB
const SURFACE_DESIGN_LAYER_NAME = translated3D;

export interface DesignLayerFlatNode {
  expandable: boolean;
  name: string;
  level: number;
  id: string;
  show: boolean;
  expanded?: boolean;
  parentId?: string;
}

export interface DesignLayerData extends DesignLayerFlatNode {
  type?: string;
  description?: string;
  children?: DesignLayerData[];
  properties?: { [key: string]: string };
  globalId?: string;
}

export interface VectorTilesMetadata {
  bounds: string; // Tiles bounding box in string format - "minX,minY,maxX,maxY"
  center: string; // Tiles center in string format - "lon,lat,height"
  json: string; // Extra json data in string format (contains layer information)
  name: string;
  description: string;
  format: string;
  maxzoom: number; // default to 22
  minzoom: number; // default to 0
  type: string;
  version: number;
}

interface Tiles3dMetadata {
  properties: any;
  geometricError: number;
  root: {
    boundingVolume: {
      /** [centerX, centerY, centerZ, ...halfAxesMatrix], 12 numbers in cartesian format */
      box?: number[];
      /** [west, south, east, north, minimum height, maximum height], coordinates in radians height in meter */
      region?: number[];
      /** [centerX, centerY, centerZ, radius], coordinates in radians height in meter */
      sphere?: number[];
    };
  };
}

interface IfcTreeJSON {
  tree: DesignLayerData;
  propertyFields: string[];
}

interface DesignData {
  design: Design | RoadDesign;
  geojsonUrl: string;
  isGeojsonLoaded?: boolean;
  tilesBaseUrl: string;
  tiles2DMetadataUrl: string;
  tiles3DMetadataUrl: string;
  layers: DesignLayerData[];
  bbox: [number, number, number, number];
  visible?: boolean;
  imageryLayer?: ImageryLayer;
  tiles3dModal?: Cesium3DTileset;
  layersFeaturesMap?: LayersFeaturesMap;
  layerPropertiesMetadataFields: DesignLayerProperty[];
  isGeoJsonEntitiesMode?: boolean;
  errorCallback?: (error: TileProviderError | DesignGeojsonLoadError) => void;
}

type LayersFeaturesMap = {
  [key: string]: TextureLayerData | VectorLayerData;
};

type TextureLayerData = {
  feature: Cesium3DTileFeature;
};

type VectorLayerData = {
  features?: Feature<Geometry>[];
  entityCollection?: EntityCollection;
};

export class DesignGeojsonLoadError extends Error {
  constructor(message?: string) {
    super('Error loading design' + (message ? ': ' + message : ''));
  }
}

@Injectable({
  providedIn: 'root'
})
export class DesignsMapManagerService {
  private siteOffset = 0;
  private siteUnits = DistanceUnitsEnum.METER;
  private designsMap = new Map<string, DesignData>();

  /** Do not use directly. Use this.mapImageryLayers */
  private imageryLayers: ImageryLayerCollection;
  mapId = 'detailed-site';
  private get mapImageryLayers() {
    if (!this.imageryLayers) {
      this.imageryLayers = this.mapsManager.getMap(this.mapId)?.getCesiumViewer()?.imageryLayers;
    }

    return this.imageryLayers;
  }

  /** Do not use directly. Use this.mapScenePrimitives */
  private scenePrimitives: PrimitiveCollection;
  private get mapScenePrimitives() {
    if (!this.scenePrimitives) {
      this.scenePrimitives = this.mapsManager.getMap(this.mapId)?.getCesiumViewer()?.scene.primitives;
    }

    return this.scenePrimitives;
  }

  private map3dTilesSilhouette: PostProcessStage;

  constructor(
    private mapsManager: MapsManagerService,
    private designsGeoJsonService: DesignsGeoJsonService,
    private resourceService: ViewerResourcesService,
    private designQuery: DetailedSiteDesignsQuery,
    private sitesQuery: DetailedSiteQuery
  ) {}

  setSiteOffset(siteOffset: number) {
    this.siteOffset = siteOffset || 0;
  }

  setSiteUnits(siteUnits: DistanceUnitsEnum) {
    this.siteUnits = siteUnits || DistanceUnitsEnum.METER;
  }

  async loadDesign(
    design: Design | RoadDesign,
    geojsonUrl: string,
    tilesBaseUrl: string,
    tiles2DMetadataUrl: string,
    tiles3DMetadataUrl: string,
    errorCallback?: (error: TileProviderError) => void
  ) {
    const currentDesignData = this.designsMap.get(design.id);
    if (currentDesignData) {
      return { layers: currentDesignData.layers, bbox: currentDesignData.bbox };
    }

    const is3d = design.cesium3DReady;
    const is2d = design.jsonReady;

    let bbox: [number, number, number, number];
    let layers: DesignLayerFlatNode[] = [];
    let layerPropertiesMetadataFields: DesignLayerProperty[];

    if (is2d) {
      const tilesMetadata: VectorTilesMetadata = await fetch(tiles2DMetadataUrl).then(res => res.json());
      bbox = tilesMetadata.bounds.split(',').map((str: string) => Number(str)) as [number, number, number, number];
      layers = this.generateFlatNodesFromLayers(JSON.parse(tilesMetadata.json)?.vector_layers);
      if (is3d) {
        layers = layers.map(l => ({ ...l, level: l.level + 1 }));
        layers.unshift({ name: translated2D, level: 0, expandable: true, expanded: false, show: false, id: `2D_layers_${design.id}` });
      }
    }

    if (is3d) {
      const tiles3dMetadata: Tiles3dMetadata = await fetch(tiles3DMetadataUrl).then(res => res.json());
      const ifcURL = (design as Design).ifcTreeFileUrl;
      if (isDefined(ifcURL)) {
        try {
          const ifcTree: IfcTreeJSON = await fetch(ifcURL).then(res => res.json());
          if (isDefined(ifcTree.tree)) {
            layers = [...layers, ...this.generateFlatNodesFromLayers([ifcTree.tree])];
            layerPropertiesMetadataFields = ifcTree.propertyFields.map(f => ({
              name: f,
              value: null,
              valueType: null,
              fieldType: DesignLayerPropertyType.METADATA,
              id: null
            }));
          }
        } catch (error) {
          console.error('Error fetching design tree data:', error);
        }
      } else {
        layers = [...layers, { name: SURFACE_DESIGN_LAYER_NAME, level: 0, expandable: false, show: false, id: SURFACE_DESIGN_LAYER_NAME }];
      }

      if (is2d && isDefined(bbox)) {
        bbox = GeoUtils.mergeBoundingBoxes(bbox, this.parse3DTilesBbox(tiles3dMetadata));
      } else {
        bbox = this.parse3DTilesBbox(tiles3dMetadata);
      }
    }

    const designData: DesignData = {
      design,
      geojsonUrl,
      tilesBaseUrl,
      tiles2DMetadataUrl,
      tiles3DMetadataUrl,
      layers,
      layersFeaturesMap: {},
      layerPropertiesMetadataFields,
      bbox,
      errorCallback,
      visible: false
    };
    this.designsMap.set(design.id, designData);
    return { layers, bbox };
  }

  private parse3DTilesBbox(tiles3dMetadata: Tiles3dMetadata): [number, number, number, number] {
    const boundingVolume = tiles3dMetadata.root.boundingVolume;
    if (boundingVolume?.box) {
      const orientedBoundingBox: OrientedBoundingBox = new Cesium.OrientedBoundingBox(
        Cesium.Cartesian3.fromArray(boundingVolume.box.slice(0, 3)),
        Cesium.Matrix3.fromArray(boundingVolume.box.slice(3))
      );
      const orientedBoundingBoxCorners = orientedBoundingBox.computeCorners();
      const boundingRectangle = Cesium.Rectangle.fromCartesianArray(orientedBoundingBoxCorners);
      return [
        Cesium.Math.toDegrees(boundingRectangle.west),
        Cesium.Math.toDegrees(boundingRectangle.south),
        Cesium.Math.toDegrees(boundingRectangle.east),
        Cesium.Math.toDegrees(boundingRectangle.north)
      ];
    } else if (boundingVolume?.region) {
      return [
        Cesium.Math.toDegrees(boundingVolume.region[0]),
        Cesium.Math.toDegrees(boundingVolume.region[1]),
        Cesium.Math.toDegrees(boundingVolume.region[2]),
        Cesium.Math.toDegrees(boundingVolume.region[3])
      ];
    } else if (boundingVolume?.sphere) {
      // We don't know how to do this transformation
      return null;
    }
  }

  private generateFlatNodesFromLayers(ifcTree: DesignLayerData[]) {
    if (!isDefined(ifcTree)) {
      return;
    }

    const flatNodes: DesignLayerFlatNode[] = [];

    const transformLayer = (layer: DesignLayerData, currentLevel: number, parentId?: string) => {
      if (!layer) {
        return;
      }

      const node: DesignLayerFlatNode = {
        name: layer.name || layer.id,
        id: layer.id || layer.globalId || layer.properties?.GlobalId || `${parentId}_${layer.name.replaceAll(' ', '')}`,
        expandable: isDefined(layer.children),
        level: currentLevel,
        show: false,
        expanded: currentLevel < 2,
        parentId
      };

      flatNodes.push(node);

      if (isDefined(layer.children)) {
        layer.children.forEach(child => transformLayer(child, currentLevel + 1, node.id));
      }
    };

    ifcTree.forEach(layer => transformLayer(layer, 0));

    return flatNodes;
  }

  isDesignLoaded(designId: string) {
    return this.designsMap.has(designId);
  }

  removeDesign(designId: string) {
    const designData = this.designsMap.get(designId);
    if (designData) {
      this.removeDesignFromMap(designData);

      this.designsMap.delete(designId);
    }
  }

  async showLayers(designId: string, selectedLayers: Set<string>) {
    const designData = this.designsMap.get(designId);
    if (designData) {
      designData.layers = designData.layers.map(layer => ({ ...layer, show: selectedLayers?.has(layer.id) || false }));
      designData.visible = selectedLayers?.size > 0;
      await this.renderDesign(designData);
    }
  }

  async showAllLayers(designId: string, show: boolean) {
    const designData = this.designsMap.get(designId);
    if (designData) {
      designData.layers = designData.layers.map(layer => ({ ...layer, show }));
      designData.visible = show;
      await this.renderDesign(designData);
    }
  }

  private async renderDesign(designData: DesignData) {
    const has2DLayers = designData.design.jsonReady;
    const has3DLayers = designData.design.cesium3DReady;
    const viewerCredentials = this.sitesQuery.getViewerCredentials();
    // Remove all map entities if not 3d
    if (!designData.isGeoJsonEntitiesMode) {
      this.destroyAllEntityCollections(designData);
    }

    const [shownLayers, hiddenLayers] = partition(designData.layers, layer => layer.show);

    this.destroyLayersEntityCollection(designData, hiddenLayers);

    if (shownLayers.length === 0) {
      this.removeDesignFromMap(designData);
      return;
    }

    if (has3DLayers) {
      const shownLayersSet = new Set(shownLayers.map(l => l.id));
      const has3DTiles = isDefined(designData.tiles3dModal);

      if (!has3DTiles) {
        let loadedFeatures = [];
        designData.tiles3dModal = new Cesium.Cesium3DTileset({
          ...TILES_3D_DEFAULT_PROPERTIES,
          url: this.resourceService.createResourceFromUrl(viewerCredentials, designData.tiles3DMetadataUrl)
        });

        designData.tiles3dModal.tileLoad.addEventListener(tile => {
          const { tileFeatures, mappedFeatures } = this.extractTileFeaturesData(designData.layersFeaturesMap, tile);
          loadedFeatures = [...loadedFeatures, ...tileFeatures];
          designData.layersFeaturesMap = { ...designData.layersFeaturesMap, ...mappedFeatures };

          const activeFeatures = loadedFeatures.filter(f => this.designQuery.isFeatureActive(getDesignFeatureId(f)));

          if (isDefined(activeFeatures)) {
            this.setLayerFeaturesSelection(activeFeatures);
          }
        });
      }

      designData.tiles3dModal.style = new Cesium.Cesium3DTileStyle({
        color: designData.design.hasSurface ? `color("${environment.whitelabel.surfaceDesignColor || 'skyblue'}", 0.4)` : undefined,
        show: {
          evaluate: feature => {
            if (!isDefined(feature)) {
              return false;
            }
            if (designData.layers.find(l => l.id === SURFACE_DESIGN_LAYER_NAME)?.show) {
              return true;
            }
            const layerId = getDesignFeatureId(feature);
            return shownLayersSet.has(layerId);
          }
        }
      });
      designData.tiles3dModal.readyPromise.then(tileset => applyOffsetTo3DTile(tileset, this.siteOffset));

      if (!has3DTiles) {
        this.mapScenePrimitives.add(designData.tiles3dModal);
      }
    }
    if (has2DLayers) {
      if (designData.isGeoJsonEntitiesMode) {
        this.destroyCurrentImagery(designData);
        let isGeojsonLoaded = designData.isGeojsonLoaded;
        if (!isGeojsonLoaded) {
          isGeojsonLoaded = await this.loadGeojson(designData);
        }

        if (isGeojsonLoaded) {
          // Load each layer geojson to map if haven't already
          await Promise.all(shownLayers.map(layer => this.loadLayerEntityCollection(designData, layer.id)));
        }
      } else {
        const shownLayerNames = shownLayers.map(layer => layer.name);
        if (!designData.imageryLayer) {
          const imageryProvider = new VectorTilesImageryProvider({
            commonProperties: {
              designId: designData.design.id,
              designName: designData.design.name
            },
            layers: shownLayerNames,
            tilesResource: this.resourceService.createResourceFromUrl(viewerCredentials, `${designData.tilesBaseUrl}/{level}/{x}/{y}.mvt`),
            maximumLevel: DEFAULT_MAX_DETAIL_LEVEL,
            siteUnits: this.siteUnits
          });
          if (designData.errorCallback) {
            const errorCallback = (error: TileProviderError) => {
              designData.errorCallback(error);
              imageryProvider.errorEvent.removeEventListener(errorCallback);
            };
            imageryProvider.errorEvent.addEventListener(errorCallback);
          }

          const mapImageryLayers = this.mapImageryLayers;
          if (mapImageryLayers) {
            const imageryLayer = mapImageryLayers.addImageryProvider(imageryProvider as any);
            designData.imageryLayer = imageryLayer;
          }
        } else {
          this.updateDesignImageryLayers(designData.imageryLayer, shownLayerNames);
        }
      }
    }
  }

  private extractTileFeaturesData(mappedFeatures: LayersFeaturesMap, tile: Cesium3DTile) {
    const tileFeatures = [];

    const processTile = (tile: Cesium3DTile) => {
      const hasContent = tile.content;

      if (hasContent) {
        const featuresLength = tile.content.featuresLength;

        if (featuresLength) {
          for (let i = 0; i < featuresLength; i++) {
            const feature = tile.content.getFeature(i);
            const globalId = getDesignFeatureId(feature);

            if (!isDefined(globalId)) {
              return;
            }

            mappedFeatures[globalId] = { feature };
            tileFeatures.push(feature);
          }
        }
      }

      if (tile.children) {
        tile.children.forEach(childTile => {
          processTile(childTile);
        });
      }
    };

    processTile(tile);

    return { tileFeatures, mappedFeatures };
  }

  private async loadGeojson(designData: DesignData) {
    const geojsonSize = await fetch(designData.geojsonUrl, { method: 'HEAD' })
      .then(res => {
        if (!res.ok || res.status !== 200) {
          return null;
        }

        const contentLength = res.headers.get('content-length');
        if (contentLength === '' || contentLength === null || contentLength === undefined) {
          return null;
        }

        return Number(contentLength);
      })
      .catch(() => null);
    if (geojsonSize !== null) {
      if (geojsonSize === 0) {
        designData.errorCallback(new DesignGeojsonLoadError('Design does not exist'));
        return false;
      } else if (geojsonSize > MAX_GEOJSON_SIZE) {
        designData.errorCallback(new DesignGeojsonLoadError('Design is too large to display'));
        return false;
      }
    }

    const geoJson: FeatureCollection<Geometry> = await fetch(designData.geojsonUrl).then(res => res.json());
    if (!isDefined(geoJson?.features)) {
      designData.errorCallback(new DesignGeojsonLoadError());
      return false;
    }

    const featuresByLayer = groupBy(geoJson.features, 'properties.Layer');

    Object.entries(featuresByLayer).forEach(([layerId, features]) => {
      (designData.layersFeaturesMap[layerId] as VectorLayerData) = { features };
    });

    designData.isGeojsonLoaded = true;

    return true;
  }

  private async loadLayerEntityCollection(designData: DesignData, layerId: string) {
    if (designData) {
      const layer = designData.layersFeaturesMap[layerId] as VectorLayerData;
      if (isDefined(layer?.features) && !isDefined(layer?.entityCollection)) {
        const entityCollection = await this.designsGeoJsonService.loadDesignGeoJson(layer.features, this.siteOffset, true);
        layer.entityCollection = entityCollection;
      }
    }
  }

  private clearMappedLayerFeatures(designData: DesignData) {
    for (let key in designData.layersFeaturesMap) {
      (designData.layersFeaturesMap[key] as TextureLayerData).feature = null;
    }
    this.clearSelectedLayerFeatures();
  }

  private destroyCurrentImagery(designData: DesignData) {
    if (designData.imageryLayer) {
      this.mapImageryLayers?.remove(designData.imageryLayer);
      designData.imageryLayer.destroy();
      designData.imageryLayer = null;
    }
  }

  private destroyCurrent3dTiles(designData: DesignData) {
    if (designData.tiles3dModal) {
      this.mapScenePrimitives?.remove(designData.tiles3dModal);
      designData.tiles3dModal.destroy();
      designData.tiles3dModal = null;
    }
  }

  private removeDesignFromMap(designData: DesignData) {
    this.destroyCurrentImagery(designData);
    this.destroyCurrent3dTiles(designData);
    this.destroyAllEntityCollections(designData);
    this.clearMappedLayerFeatures(designData);
  }

  private updateDesignImageryLayers(imageryLayer: ImageryLayer, shownLayerNames: string[]) {
    if (imageryLayer) {
      const imageryProvider = imageryLayer.imageryProvider as VectorTilesImageryProvider;
      imageryProvider.setLayers(shownLayerNames);
    }
  }

  private destroyLayersEntityCollection(designData: DesignData, layers: DesignLayerData[]) {
    layers.forEach(layer => {
      const mappedLayer = designData.layersFeaturesMap?.[layer.id] as VectorLayerData;
      const entityCollection = mappedLayer?.entityCollection;
      if (isDefined(entityCollection)) {
        entityCollection.removeAll();
        mappedLayer.entityCollection = null;
      }
    });
  }

  private destroyAllEntityCollections(designData: DesignData) {
    if (designData) {
      this.destroyLayersEntityCollection(designData, designData.layers);
    }
  }

  async setDesignProjectionView(designId: string, isGeoJsonEntitiesMode: boolean) {
    const designData = this.designsMap.get(designId);
    if (designData && designData.isGeoJsonEntitiesMode !== isGeoJsonEntitiesMode) {
      designData.isGeoJsonEntitiesMode = isGeoJsonEntitiesMode;

      if (designData.visible) {
        await this.renderDesign(designData);
      }
    }
  }

  clear() {
    Array.from(this.designsMap.values()).forEach(designData => {
      this.removeDesignFromMap(designData);
    });

    this.designsMap.clear();
    this.imageryLayers = null;
    this.scenePrimitives = null;
    this.map3dTilesSilhouette = null;
  }

  getLayerFeatures(designId: string) {
    const designData = this.designsMap.get(designId);
    const activeLayerId = this.designQuery.getActiveLayerId();

    if (!isDefined(designData) || !isDefined(activeLayerId)) {
      return null;
    }

    const activeFeatureIds = this.designQuery.getActiveLayerFeaturesId();
    const mappedFeatures = designData.layersFeaturesMap;
    const activeLayerData = mappedFeatures?.[activeLayerId];
    let feature = [];

    for (let key in mappedFeatures) {
      if (activeFeatureIds?.has(key) && (mappedFeatures[key] as TextureLayerData).feature) {
        feature.push((mappedFeatures[key] as TextureLayerData).feature);
      }
    }

    if (!isDefined(activeLayerData) && !isDefined(feature)) {
      return null;
    } else {
      return feature;
    }
  }

  clearSelectedLayerFeatures() {
    if (isDefined(this.map3dTilesSilhouette)) {
      this.map3dTilesSilhouette.selected = [];
    }
  }

  setLayerFeaturesSelection(features: Cesium3DTileFeature[]) {
    if (!isDefined(this.map3dTilesSilhouette)) {
      this.initMapPostProcess();
    }
    this.map3dTilesSilhouette.selected = features;
  }

  private initMapPostProcess() {
    const viewer = this.mapsManager.getMap(this.mapId)?.getCesiumViewer();
    this.map3dTilesSilhouette = Cesium.PostProcessStageLibrary.isSilhouetteSupported(viewer.scene)
      ? Cesium.PostProcessStageLibrary.createEdgeDetectionStage()
      : null;

    if (isDefined(this.map3dTilesSilhouette)) {
      this.map3dTilesSilhouette.uniforms.color = Cesium.Color.fromCssColorString(environment.whitelabel.primaryColor);
      this.map3dTilesSilhouette.uniforms.length = 0.01;
      this.map3dTilesSilhouette.selected = [];

      viewer.scene.postProcessStages.add(Cesium.PostProcessStageLibrary.createSilhouetteStage([this.map3dTilesSilhouette]));
    }
  }

  getVisibleDesigns() {
    return Array.from(this.designsMap.values())
      .filter(value => value.visible)
      .map(({ design }) => ({ id: design.id, type: design.type }));
  }

  getDesignLayerMetaDataProperties(designId: string) {
    const designData = this.designsMap.get(designId);
    return designData?.layerPropertiesMetadataFields ?? [];
  }
}
