/**
 * Datumate Tenant Service
 * APIs
 *
 * OpenAPI spec version: 3.2.8
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ShareResourceUsersListRequest } from './shareResourceUsersListRequest';

export interface ShareResourceRequest {
  creationDate?: string;
  entityName?: string;
  existingUsers?: Array<ShareResourceUsersListRequest>;
  link?: string;
  missionFlightDate?: string;
  newUsers?: Array<ShareResourceUsersListRequest>;
  senderFirstName?: string;
  senderLastName?: string;
  siteName?: string;
  type?: ShareResourceRequest.TypeEnum;
}
export namespace ShareResourceRequest {
  export type TypeEnum =
    | 'ANALYTIC'
    | 'FLIGHT'
    | 'MEASUREMENT'
    | 'DESIGN'
    | 'MODEL_EDIT'
    | 'REPORT'
    | 'ACTIVITY'
    | 'ROAD_DESIGN'
    | 'ANNOTATION'
    | 'SITE';
  export const TypeEnum = {
    ANALYTIC: 'ANALYTIC' as TypeEnum,
    FLIGHT: 'FLIGHT' as TypeEnum,
    MEASUREMENT: 'MEASUREMENT' as TypeEnum,
    DESIGN: 'DESIGN' as TypeEnum,
    MODEL_EDIT: 'MODEL_EDIT' as TypeEnum, // DON'T REMOVE THE UNDER SCORE (MODEL_EDIT => MODELEDIT)
    REPORT: 'REPORT' as TypeEnum,
    ACTIVITY: 'ACTIVITY' as TypeEnum,
    ROAD_DESIGN: 'ROAD_DESIGN' as TypeEnum, // DON'T REMOVE THE UNDER SCORE (ROAD_DESIGN => ROADDESIGN)
    ANNOTATION: 'ANNOTATION' as TypeEnum,
    SITE: 'SITE' as TypeEnum
  };
}
