/**
 * Datumate Integration Service
 * API's for integration service
 *
 * OpenAPI spec version: 1.6.2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface GetRemoteSiteResponse {
  artifact?: GetRemoteSiteResponse.ArtifactEnum;
  id?: string;
  integration?: GetRemoteSiteResponse.IntegrationEnum;
  name?: string;
}
export namespace GetRemoteSiteResponse {
  export type ArtifactEnum = 'DESIGN' | 'ANNOTATION';
  export const ArtifactEnum = {
    DESIGN: 'DESIGN' as ArtifactEnum,
    ANNOTATION: 'ANNOTATION' as ArtifactEnum
  };
  export type IntegrationEnum = 'PROCORE' | 'AUTODESK';
  export const IntegrationEnum = {
    PROCORE: 'PROCORE' as IntegrationEnum,
    AUTODESK: 'AUTODESK' as IntegrationEnum
  };
}
