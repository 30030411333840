/**
 * Datumate
 * API's for mile stone I
 *
 * OpenAPI spec version: 3.5.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface GetFeatureDataResponse {
  featureId?: string;
  fieldId?: string;
  fieldType?: GetFeatureDataResponse.FieldTypeEnum;
  id?: string;
  name?: string;
  value?: string;
  valueType?: GetFeatureDataResponse.ValueTypeEnum;
}
export namespace GetFeatureDataResponse {
  export type FieldTypeEnum = 'METADATA' | 'CUSTOM';
  export const FieldTypeEnum = {
    METADATA: 'METADATA' as FieldTypeEnum,
    CUSTOM: 'CUSTOM' as FieldTypeEnum
  };
  export type ValueTypeEnum = 'STRING' | 'DATE' | 'DOUBLE' | 'BOOLEAN';
  export const ValueTypeEnum = {
    STRING: 'STRING' as ValueTypeEnum,
    DATE: 'DATE' as ValueTypeEnum,
    DOUBLE: 'DOUBLE' as ValueTypeEnum,
    BOOLEAN: 'BOOLEAN' as ValueTypeEnum
  };
}
