import { Rectangle, Request, Resource, TerrainData } from 'cesium';

export class TerrainProviderWithEmptyTiles extends Cesium.CesiumTerrainProvider {
  constructor({ url }: { url: Resource | string }) {
    super({ url });
  }

  requestTileGeometry(x: number, y: number, level: number, request: Request): Promise<TerrainData> | undefined {
    const promise = super.requestTileGeometry(x, y, level, request);
    if (!promise) {
      return promise;
    }

    return promise.then(terrainData => {
      // Fix tile bounding sphere to be according tile coords in empty tiles
      if (terrainData && terrainData._minimumHeight === 0 && terrainData._maximumHeight === 0) {
        const tileRectangle: Rectangle = this.tilingScheme.tileXYToRectangle(x, y, level);
        terrainData._boundingSphere = Cesium.BoundingSphere.fromPoints(Cesium.Rectangle.subsample(tileRectangle));
      }

      return terrainData;
    });
  }
}
