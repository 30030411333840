import {
  along,
  bbox,
  booleanPointInPolygon,
  Feature,
  featureCollection,
  FeatureCollection,
  length,
  lengthToRadians,
  lineString,
  MultiPoint,
  planepoint,
  point,
  Point,
  pointGrid,
  polygon,
  Polygon,
  tin,
  truncate
} from '@turf/turf';
import { Cartesian3 } from 'angular-cesium';
import { environment } from '../../../environments/environment';
import { isDefined } from '../../shared/utils/general';
import { Cartographic, GeoUtils } from '../../shared/utils/geo';
import { roundTo } from '../../shared/utils/math';
import {
  ActivityMeasurement,
  ActivityMeasurementBaseSurfaceType,
  ActivityMeasurementModelSourceType,
  ActivityMeasurementValues
} from '../state/detailed-site-activities/detailed-site-activities.model';
import {
  BaseSurfaceType,
  CalcStatus,
  MapEntity,
  SourceModel,
  TaskOrDesignInfo,
  TaskOrDesignValues
} from '../state/detailed-site-entities/detailed-site-entities.model';
import { DetailedSiteQuery } from '../state/detailed-site.query';
import { Terrain, TerrainType } from './terrain-provider.service';
import { TerrainSamplingService } from './terrain-sampling.service';
import { DetailedSiteService } from '../state/detailed-site.service';

export interface PrecalcData {
  feature: Feature;
  cellSide: number;
  horizontalArea?: number;
  pointGridFeatureCollection?: FeatureCollection<Point>;
  isBboxSamplePointsInsidePolygon?: (0 | 1)[];
  bboxPointsCountDimentions?: {
    bboxPointsCountWidth: number;
    bboxPointsCountHeight: number;
  };
}

export interface BaseSurfaceProps {
  type: BaseSurfaceType | ActivityMeasurementBaseSurfaceType;
  terrain: Terrain;
}

export interface BaseSurfaceSamplesHeights {
  id: string; // taskId for 'INTERPOLATED' | 'TASK', designId for 'DESIGN'
  type: 'TASK' | 'DESIGN' | 'INTERPOLATED';
  heights: number[];
}

export interface TerrainSamplesValues {
  id: string; // taskId for 'TASK', designId for 'DESIGN'
  type: 'TASK' | 'DESIGN';
  samplesValues: number[];
}

export interface GeoJsonProperties<T = any> {
  bboxPointsCountWidth?: number;
  bboxPointsCountHeight?: number;
  cellSide: number;
  isBboxSamplePointsInsidePolygon?: Array<0 | 1>;
  surfaceSamplesHeights?: BaseSurfaceSamplesHeights[];
  terrainSamplesValues?: TerrainSamplesValues[];
  vizOptions?: T;
}

export abstract class CalcService {
  constructor() {}

  abstract calcResults(
    entity: MapEntity | ActivityMeasurement,
    siteId: string,
    modelsData?: { id: string; type: 'TASK' | 'DESIGN' } | { id: string; type: 'TASK' | 'DESIGN' }[],
    precalcData?: PrecalcData
  ): Promise<{
    calcResult: TaskOrDesignValues[] | ActivityMeasurementValues;
    samplePoints?: Feature<MultiPoint, GeoJsonProperties>;
    calcStatus?: CalcStatus;
  }>;
}

export abstract class PolylineCalcService extends CalcService {
  constructor(protected siteQuery: DetailedSiteQuery) {
    super();
  }

  precalc(verticesCartesian3: Cartesian3[], { maxPointsToSample, minPointsDistanceMeters } = environment.measurements): PrecalcData {
    const polylineFeature = lineString(
      verticesCartesian3.map(p => {
        const latlon = GeoUtils.cartesian3ToDeg(p);
        return [latlon.longitude, latlon.latitude];
      })
    );

    const polylineLength = length(polylineFeature, { units: 'meters' });
    const neededSamplesCount = polylineLength / minPointsDistanceMeters;
    const sampleCount = Math.min(neededSamplesCount, maxPointsToSample);
    const cellSide = polylineLength / sampleCount;

    const pointGridFeatureCollection = featureCollection<Point>([]);
    for (let distance = 0; distance < polylineLength; distance += cellSide) {
      pointGridFeatureCollection.features.push(along(polylineFeature, distance, { units: 'meters' }));
    }

    return {
      feature: polylineFeature,
      pointGridFeatureCollection,
      cellSide
    };
  }

  // Remove sample point heights near edges of terrain
  protected smoothCoordinates(terrainType: TerrainType, coordinates: Cartographic[]) {
    const noHeightNumber = terrainType === 'TASK' ? this.siteQuery.getTerrainHeightOffset() : 0;
    const omitSamplesCount = terrainType === 'TASK' ? 3 : 9;

    const result: Cartographic[] = [];

    let dataAfterEmptyCounter = Number.MAX_SAFE_INTEGER;
    for (const coord of coordinates) {
      if (!isDefined(coord.height) || roundTo(coord.height) === noHeightNumber) {
        // There were samples with data before - remove last omitSamplesCount samples data
        if (dataAfterEmptyCounter > 0) {
          for (let i = 1; i <= omitSamplesCount; i++) {
            if (result[result.length - i]) {
              result[result.length - i] = { ...result[result.length - i], height: undefined };
            }
          }
        }
        result.push(coord);
        dataAfterEmptyCounter = 0;
      } else {
        // If less than omitSamplesCount samples with data after NaN - remove samples
        if (dataAfterEmptyCounter < omitSamplesCount) {
          result.push({ ...coord, height: undefined });
        } else {
          result.push(coord);
        }
        dataAfterEmptyCounter++;
      }
    }

    return result;
  }

  protected calcSurfaceDistanceAndElevationLimits(coordinatesWithHeight: Cartographic[]) {
    let surfaceDistance = 0;
    let elevationMin: number;
    let elevationMax: number;
    for (let i = 0; i < coordinatesWithHeight.length - 1; i++) {
      surfaceDistance += GeoUtils.distance(coordinatesWithHeight[i], coordinatesWithHeight[i + 1]);

      const modelHeight = coordinatesWithHeight[i].height;
      if (elevationMax === undefined || modelHeight > elevationMax) {
        elevationMax = modelHeight;
      }
      if (elevationMin === undefined || modelHeight < elevationMin) {
        elevationMin = modelHeight;
      }
    }
    return { surfaceDistance, elevationMin, elevationMax };
  }
}

export abstract class PolygonCalcService extends PolylineCalcService {
  constructor(protected siteQuery: DetailedSiteQuery, protected terrainSampling: TerrainSamplingService) {
    super(siteQuery);
  }

  // For Cesium TIN algorithm triangles generation
  private inferGranularity(positions: Cartesian3[], { maxPointsToSample, minPointsDistanceMeters } = environment.measurements) {
    const area = this.calcHorizontalArea(positions);
    const approxNeededSamplesCount = area / minPointsDistanceMeters ** 2;

    // Based on the following formula to find distance between points of right triangles with same length sides that are filling a polygon:
    // distance = sqrt(area / (numberOfPoints * 0.5))
    const minDistance =
      approxNeededSamplesCount > maxPointsToSample ? Math.sqrt(area / (maxPointsToSample * 0.5)) : minPointsDistanceMeters;
    return lengthToRadians(minDistance, 'meters');
  }

  public calcHorizontalArea(positions: Cartesian3[]) {
    // Create triangles from polygon positions
    const polygonGeometry = Cesium.PolygonGeometry.fromPositions({
      positions: positions.map(p => p.clone()) // Clone to avoid readonly errors
    });
    const geometry = Cesium.PolygonGeometry.createGeometry(polygonGeometry);

    // Create cartesian list from position values array
    const geometryPositions = geometry.attributes.position.values;
    const trianglePositions: Cartesian3[] = [];
    for (let i = 0; i < geometryPositions.length; i += 3) {
      trianglePositions.push(new Cesium.Cartesian3(geometryPositions[i], geometryPositions[i + 1], geometryPositions[i + 2]));
    }

    // Calculate area of each triangle and sum them up
    let area = 0;
    for (let i = 0; i < geometry.indices.length; i += 3) {
      const a = trianglePositions[geometry.indices[i]];
      const b = trianglePositions[geometry.indices[i + 1]];
      const c = trianglePositions[geometry.indices[i + 2]];

      area += this.calc3DTriangleArea(a, b, c);
    }

    return area;
  }

  protected async calcSurfaceArea(positions: Cartesian3[], terrain: Terrain) {
    // Create triangles from polygon positions
    const polygonGeometry = Cesium.PolygonGeometry.fromPositions({
      positions: positions.map(p => p.clone()), // Clone to fix readonly positions
      granularity: this.inferGranularity(positions)
    });
    const geometry = Cesium.PolygonGeometry.createGeometry(polygonGeometry);

    // Create cartesian list from position values array
    const geometryPositions = geometry.attributes.position.values;
    const trianglePositions = [];
    for (let i = 0; i < geometryPositions.length; i += 3) {
      trianglePositions.push(new Cesium.Cartesian3(geometryPositions[i], geometryPositions[i + 1], geometryPositions[i + 2]));
    }

    // Sample each position in position values array to get accurate height
    const trianglePositionsCartograpic = await this.terrainSampling.sampleTerrain(trianglePositions, terrain);
    const trianglePositionsWithHeight = trianglePositionsCartograpic.map(p =>
      Cesium.Cartesian3.fromDegrees(p.longitude, p.latitude, p.height)
    );

    // Calculate area of each triangle and sum them up
    let area = 0;
    for (let i = 0; i < geometry.indices.length; i += 3) {
      const a = trianglePositionsWithHeight[geometry.indices[i]];
      const b = trianglePositionsWithHeight[geometry.indices[i + 1]];
      const c = trianglePositionsWithHeight[geometry.indices[i + 2]];

      area += this.calc3DTriangleArea(a, b, c);
    }

    return area;
  }

  private calc3DTriangleArea(a: Cartesian3, b: Cartesian3, c: Cartesian3) {
    // area = 1/2 * |(b - a) X (c - a)|
    const v = Cesium.Cartesian3.subtract(b, a, new Cesium.Cartesian3());
    const w = Cesium.Cartesian3.subtract(c, a, new Cesium.Cartesian3());
    const areaVector = Cesium.Cartesian3.cross(v, w, new Cesium.Cartesian3());
    return Cesium.Cartesian3.magnitude(areaVector) / 2;
  }
}

export abstract class PolygonWithSamplesCalcService extends PolygonCalcService {
  constructor(
    protected siteQuery: DetailedSiteQuery,
    protected terrainSampling: TerrainSamplingService,
    protected siteService: DetailedSiteService
  ) {
    super(siteQuery, terrainSampling);
  }

  private createPointGrid(polygonFeature: Feature<Polygon>, cellSideMeters: number, withMask: boolean) {
    const options = {
      units: 'meters',
      gridType: 'point',
      mask: withMask ? polygonFeature : null
    };
    const createdPointGrid = pointGrid(bbox(polygonFeature), cellSideMeters, options as any);
    truncate(createdPointGrid, { precision: 7, coordinates: 2, mutate: true });
    return createdPointGrid;
  }

  precalc(verticesCartesian3: Cartesian3[]): PrecalcData {
    const horizontalArea = this.calcHorizontalArea(verticesCartesian3);
    const polygonFeature = this.getPolygonFeatureFromCartesian3(verticesCartesian3);
    const cellSide = GeoUtils.inferCellSide(polygonFeature);

    // Get all samples points inside bbox
    const pointGridFeatureCollection = this.createPointGrid(polygonFeature, cellSide, false);
    // Create mask array for texture
    const isBboxSamplePointsInsidePolygon = pointGridFeatureCollection.features.map(p => this.binaryPointInPolygon(p, polygonFeature));
    // Leave only points inside the polygon
    pointGridFeatureCollection.features = pointGridFeatureCollection.features.filter(p => booleanPointInPolygon(p, polygonFeature));
    // Get bbox dimentions for texture
    const bboxPointsCountDimentions = this.getBboxDimensionsPointsCount(bbox(polygonFeature), cellSide);

    return {
      horizontalArea,
      feature: polygonFeature,
      cellSide,
      pointGridFeatureCollection,
      isBboxSamplePointsInsidePolygon,
      bboxPointsCountDimentions
    };
  }

  protected async getBaseSurfaceSamplePointsCartographic(
    verticesCartesian3: Cartesian3[],
    baseSurfaceProps: BaseSurfaceProps,
    pointGridFeatureCollection: FeatureCollection<Point>
  ): Promise<Cartographic[]> {
    switch (baseSurfaceProps.type) {
      case BaseSurfaceType.INTERPOLATED: {
        // Get interpolated samples points inside polygon
        const bsPointGridFeatureCollectionHeight = await this.createSamplePointsCartoghraphicInterpolatedHeight(
          verticesCartesian3,
          baseSurfaceProps.terrain,
          pointGridFeatureCollection
        );

        return bsPointGridFeatureCollectionHeight?.features.map(
          point =>
            ({
              longitude: point.geometry.coordinates[0],
              latitude: point.geometry.coordinates[1],
              height: point.properties.elevation
            } as Cartographic)
        );
      }
      case BaseSurfaceType.DESIGN:
      case BaseSurfaceType.TASK: {
        return await this.createSamplePointsCartographic(pointGridFeatureCollection, baseSurfaceProps.terrain);
      }
      case BaseSurfaceType.CUSTOMELEVATION:
      case BaseSurfaceType.MINELEVATION:
      default: {
        // no need for surfacePositionsGrid
        return null;
      }
    }
  }

  private async createSamplePointsCartoghraphicInterpolatedHeight(
    verticesCartesian3: Cartesian3[],
    terrain: Terrain,
    pointGrid: FeatureCollection<Point>
  ) {
    const verticesCartographic = await this.terrainSampling.sampleTerrain(verticesCartesian3, terrain);

    const verticesPointFeaturesHeight = verticesCartographic.map(vertice =>
      point([vertice.longitude, vertice.latitude], { elevation: vertice.height })
    );

    const tinResult = tin(featureCollection(verticesPointFeaturesHeight), 'elevation');
    const triangles = tinResult.features;
    pointGrid.features.forEach(currentPoint => {
      const relevantTriangle = triangles.find(triangle => booleanPointInPolygon(currentPoint, triangle));
      if (relevantTriangle) {
        currentPoint.properties.elevation = planepoint(currentPoint, relevantTriangle) ?? undefined;
      }
    });

    return pointGrid;
  }

  protected async createSamplePointsCartographic(pointGrid: FeatureCollection<Point>, terrain: Terrain) {
    const pointsCartesian3 = pointGrid.features.map(f =>
      Cesium.Cartesian3.fromDegrees(f.geometry.coordinates[0], f.geometry.coordinates[1])
    );
    try {
      return await this.terrainSampling.sampleTerrain(pointsCartesian3, terrain);
    } catch (error) {
      console.error('Error creating sample points', error);
    }
  }

  protected convertCartographicToTurfPosition(point: Cartographic) {
    return [roundTo(point.longitude, 7), roundTo(point.latitude, 7), roundTo(point.height, 3)];
  }

  protected convertCartographicTo2dTurfPosition(point: Cartographic) {
    return [roundTo(point.longitude, 7), roundTo(point.latitude, 7)];
  }

  protected getBboxDimensionsPointsCount(bbox: number[], cellSide: number) {
    const widthLine = lineString([
      [bbox[0], bbox[1]],
      [bbox[2], bbox[1]]
    ]);
    const heightLine = lineString([
      [bbox[0], bbox[1]],
      [bbox[0], bbox[3]]
    ]);

    return {
      bboxPointsCountWidth: Math.trunc(length(widthLine, { units: 'meters' }) / cellSide) + 1,
      bboxPointsCountHeight: Math.trunc(length(heightLine, { units: 'meters' }) / cellSide) + 1
    };
  }

  private binaryPointInPolygon(point: Feature<Point>, polygon: Feature<Polygon>) {
    return booleanPointInPolygon(point, polygon) ? 1 : 0;
  }

  protected async getBaseSurfaceTerrain(
    siteId: string,
    taskOrDesignInfo: TaskOrDesignInfo,
    baseSurfaceType: BaseSurfaceType | ActivityMeasurementBaseSurfaceType,
    baseSurfaceId: string,
    sourceModel: SourceModel | ActivityMeasurementModelSourceType
  ): Promise<Terrain> {
    switch (baseSurfaceType) {
      case BaseSurfaceType.INTERPOLATED:
        return await this.siteService.getTerrainProvider(siteId, taskOrDesignInfo.id, taskOrDesignInfo.type, sourceModel);
      case BaseSurfaceType.TASK:
      case BaseSurfaceType.DESIGN:
        return await this.siteService.getTerrainProvider(siteId, baseSurfaceId, baseSurfaceType as 'TASK' | 'DESIGN', sourceModel);
      case BaseSurfaceType.CUSTOMELEVATION:
      case BaseSurfaceType.MINELEVATION:
      default:
        // no needs terrain - base surface is constant
        return null;
    }
  }

  private getPolygonFeatureFromCartesian3(points: Cartesian3[]) {
    const verticesCartographicDeg = points.map(p => GeoUtils.cartesian3ToDeg(p));
    const verticesTurfPositions = verticesCartographicDeg.map(latlon => [latlon.longitude, latlon.latitude]);
    return polygon([[...verticesTurfPositions, verticesTurfPositions[0]]]);
  }

  protected async getModelSamplesHeights(
    siteId: string,
    modelData: { id: string; type: 'TASK' | 'DESIGN' },
    sourceModel: SourceModel,
    pointGridFeatureCollection: FeatureCollection<Point>,
    currentSurfaceSamplesHeights: BaseSurfaceSamplesHeights[]
  ) {
    // handle model samples height
    // if surface's heights are already saved - take them, if not - update from terrain
    let isNewModelSamplesHeights = false;
    let modelSamplesHeights: BaseSurfaceSamplesHeights = currentSurfaceSamplesHeights?.find(
      data => data.id === modelData.id && data.type === 'TASK'
    );
    if (!modelSamplesHeights) {
      isNewModelSamplesHeights = true;
      const modelTerrain = await this.siteService.getTerrainProvider(siteId, modelData.id, modelData.type, sourceModel);
      const modelSamplePointsCartographic = modelTerrain
        ? await this.createSamplePointsCartographic(pointGridFeatureCollection, modelTerrain)
        : null;
      modelSamplesHeights = {
        ...modelData,
        heights: modelSamplePointsCartographic?.map(p => roundTo(p.height))
      };
    }
    return { modelSamplesHeights, isNewModelSamplesHeights };
  }

  protected async getBaseSurfaceSamplesHeights(
    siteId: string,
    taskOrDesignInfo: TaskOrDesignInfo,
    entity: MapEntity,
    pointGridFeatureCollection: FeatureCollection<Point>,
    currentSurfaceSamplesHeights: BaseSurfaceSamplesHeights[]
  ) {
    // handle base surface samples height
    // if surface's heights are already saved - take them, if not - update from terrain or calc (depends on type)
    let isNewBaseSurfaceSamplesHeights = false;
    const baseSurfaceProps: BaseSurfaceProps = {
      type: entity?.baseSurface?.type,
      terrain: await this.getBaseSurfaceTerrain(
        siteId,
        taskOrDesignInfo,
        entity.baseSurface.type,
        entity.baseSurface.id,
        entity.sourceModel
      )
    };
    let baseSurfaceSamplesHeights: BaseSurfaceSamplesHeights = currentSurfaceSamplesHeights?.find(
      data => data.id === baseSurfaceProps.terrain?.taskOrDesignId && data.type === baseSurfaceProps.type
    );
    if (!baseSurfaceSamplesHeights?.heights) {
      const bsSamplePointsCartographic = await this.getBaseSurfaceSamplePointsCartographic(
        entity?.positions,
        baseSurfaceProps,
        pointGridFeatureCollection
      );
      // bsSamplePointsCartographic is null if BS type is MINELEVATION or CUSTOMELEVATION
      if (baseSurfaceProps.type !== BaseSurfaceType.MINELEVATION && baseSurfaceProps.type !== BaseSurfaceType.CUSTOMELEVATION) {
        isNewBaseSurfaceSamplesHeights = true;
        baseSurfaceSamplesHeights = {
          id: baseSurfaceProps.terrain.taskOrDesignId,
          type: baseSurfaceProps.type as 'TASK' | 'DESIGN' | 'INTERPOLATED',
          heights: bsSamplePointsCartographic?.map(p => roundTo(p.height))
        };
      }
    }
    return { baseSurfaceSamplesHeights, isNewBaseSurfaceSamplesHeights };
  }
}
