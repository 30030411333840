/**
 * Notification Management Service
 * API's for mile stone I
 *
 * OpenAPI spec version: 1.4.9
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface GetNotificationResponse {
  annotationId?: string;
  creationTime?: Date;
  id?: string;
  message?: string;
  read?: boolean;
  seen?: boolean;
  severity?: GetNotificationResponse.SeverityEnum;
  siteId?: string;
  taskId?: string;
}
export namespace GetNotificationResponse {
  export type SeverityEnum = 'INFO' | 'WARNING' | 'ERROR';
  export const SeverityEnum = {
    INFO: 'INFO' as SeverityEnum,
    WARNING: 'WARNING' as SeverityEnum,
    ERROR: 'ERROR' as SeverityEnum
  };
}
