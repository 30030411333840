import { Injectable } from '@angular/core';
import { PolygonCalcService } from '../../services/calc-services';
import { TerrainSamplingService } from '../../services/terrain-sampling.service';
import { ActivityMeasurement, ActivityMeasurementValues } from '../../state/detailed-site-activities/detailed-site-activities.model';
import { DetailedSiteQuery } from '../../state/detailed-site.query';
import { DetailedSiteService } from '../../state/detailed-site.service';

@Injectable({
  providedIn: 'root'
})
export class ActivityAreaCalcService extends PolygonCalcService {
  constructor(
    protected siteQuery: DetailedSiteQuery,
    protected terrainSampling: TerrainSamplingService,
    private siteService: DetailedSiteService
  ) {
    super(siteQuery, terrainSampling);
  }

  async calcResults(
    measurement: ActivityMeasurement,
    siteId: string,
    modelData: { id: string; type: 'TASK' | 'DESIGN' }
  ): Promise<{ calcResult: ActivityMeasurementValues }> {
    const modelTerrain = await this.siteService.getTerrainProvider(siteId, modelData.id, modelData.type, measurement.sourceModelType);
    const surfaceArea =
      modelTerrain.type === 'FLAT'
        ? this.calcHorizontalArea(measurement.positions)
        : await this.calcSurfaceArea(measurement.positions, modelTerrain);

    return {
      calcResult: {
        values: { surfaceArea }
      }
    };
  }
}
