import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'dialog-layout',
  templateUrl: './dialog-layout.component.html',
  styleUrls: ['./dialog-layout.component.scss']
})
export class DialogLayoutComponent implements OnInit {
  @Output() closeDialog = new EventEmitter();
  @Input() header?: string = null;
  @Input() subHeaders?: string[] = null;
  @Input() subheadersPosition: 'right' | 'bottom' = 'right';
  @Input() isSubHeaderWarning? = false;
  @Input() disableClose: boolean;

  constructor() {}

  ngOnInit() {}
}
