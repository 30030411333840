import { Injectable } from '@angular/core';
import { Cartographic } from '../../../shared/utils/geo';
import { PolygonWithSamplesCalcService } from '../../services/calc-services';
import { TerrainSamplingService } from '../../services/terrain-sampling.service';
import { MapEntity, TaskOrDesignValues } from '../../state/detailed-site-entities/detailed-site-entities.model';
import { DetailedSiteQuery } from '../../state/detailed-site.query';
import { FIELD_MAPPING } from '../../state/detailed-site.utils';
import { DetailedSiteService } from '../../state/detailed-site.service';

@Injectable({
  providedIn: 'root'
})
export class ModelEditCalcService extends PolygonWithSamplesCalcService {
  constructor(
    protected siteQuery: DetailedSiteQuery,
    protected terrainSampling: TerrainSamplingService,
    protected siteService: DetailedSiteService
  ) {
    super(siteQuery, terrainSampling, siteService);
  }

  async calcResults(
    entity: MapEntity,
    siteId: string,
    modelsData: { id: string; type: 'TASK' | 'DESIGN' }[]
  ): Promise<{ calcResult: TaskOrDesignValues[] }> {
    const { horizontalArea, pointGridFeatureCollection } = this.precalc(entity?.positions);
    let calcResult: TaskOrDesignValues[] = entity?.calcResult ?? [];
    for (let i = 0; i < modelsData.length; i++) {
      const modelData = modelsData[i];
      const modelTerrain = await this.siteService.getTerrainProvider(siteId, modelData.id, modelData.type, entity.sourceModel);
      const modelSamplePointsCartographic = modelTerrain
        ? await this.createSamplePointsCartographic(pointGridFeatureCollection, modelTerrain)
        : null;

      let elevationMin = Number.MAX_SAFE_INTEGER;
      let elevationMax = Number.MIN_SAFE_INTEGER;
      const pointsCount = modelSamplePointsCartographic.length;

      const updateElevationMin = (a: Cartographic[], index: number) => {
        if (a[index].height < elevationMin) {
          elevationMin = a[index].height;
        }
      };

      const updateElevationMax = (a: Cartographic[], index: number) => {
        if (a[index].height > elevationMax) {
          elevationMax = a[index].height;
        }
      };

      const actionsList = [updateElevationMin, updateElevationMax];

      for (let i = 0; i < pointsCount; i++) {
        actionsList.forEach(f => f(modelSamplePointsCartographic, i));
      }

      calcResult = [
        ...calcResult,
        {
          id: modelData.id,
          type: modelData.type,
          values: [
            { ...FIELD_MAPPING.horizontalArea, value: horizontalArea },
            { ...FIELD_MAPPING.elevationMin, value: elevationMin },
            { ...FIELD_MAPPING.elevationMax, value: elevationMax }
          ]
        }
      ];
    }

    return { calcResult };
  }
}
