import { Injectable } from '@angular/core';
import { Resource } from 'cesium';
import { firstValueFrom, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CloudFrontPreSignedPolicy } from '../../../generated/tenant/model/cloudFrontPreSignedPolicy';
import { DetailedSiteQuery } from '../../detailed-site/state/detailed-site.query';

@Injectable({
  providedIn: 'root'
})
export class ViewerResourcesService {
  constructor(private siteQuery: DetailedSiteQuery) {}

  createResourceFromUrl(viewerCredentials: CloudFrontPreSignedPolicy, url: string): Resource {
    return new Cesium.Resource({
      url,
      queryParameters: {
        Policy: viewerCredentials.policy,
        Signature: viewerCredentials.signature,
        'Key-Pair-Id': viewerCredentials.keyPairId
      },
      retryAttempts: 1,
      retryCallback: (resource, error) => this.refreshTokenRetryCallback(resource, error, viewerCredentials)
    });
  }

  private refreshTokenRetryCallback = (resource, error, viewerCredentials) => {
    if (error.statusCode === 403) {
      // Check if credentials are expired, if not - don't retry
      if (viewerCredentials.expiration <= Date.now()) {
        return false;
      }

      return firstValueFrom(
        this.siteQuery.viewerCredentials$.pipe(
          map(credentials => {
            resource.queryParameters.Policy = credentials.policy;
            resource.queryParameters.Signature = credentials.signature;
            resource.queryParameters['Key-Pair-Id'] = credentials.keyPairId;
            return true;
          }),
          catchError(_ => of(false))
        )
      );
    }

    return false;
  };
}
