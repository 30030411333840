/**
 * Measurements Management Service
 * APIs document (version: 2.1.6)
 *
 * OpenAPI spec version: 2.1.6
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CoordinateBlock } from './coordinateBlock';
import { CoordinateModel } from './coordinateModel';
import { ModelEditData } from './modelEditData';

export interface GetModelEditResponse {
  coordinates?: Array<CoordinateModel>;
  createdBy?: string;
  creationTime?: Date;
  data?: Array<ModelEditData>;
  dataVersion?: number;
  id?: string;
  lastModifiedTime?: Date;
  name?: string;
  positions?: Array<CoordinateBlock>;
  positionsElevation?: { [key: string]: Array<number> };
  sourceModel?: GetModelEditResponse.SourceModelEnum;
  type?: GetModelEditResponse.TypeEnum;
}
export namespace GetModelEditResponse {
  export type SourceModelEnum = 'DSM' | 'DTM' | 'FLAT';
  export const SourceModelEnum = {
    DSM: 'DSM' as SourceModelEnum,
    DTM: 'DTM' as SourceModelEnum,
    FLAT: 'FLAT' as SourceModelEnum
  };
  export type TypeEnum = 'FILTER' | 'UNFILTER';
  export const TypeEnum = {
    FILTER: 'FILTER' as TypeEnum,
    UNFILTER: 'UNFILTER' as TypeEnum
  };
}
