import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { produce } from 'immer';
import { GetNFIHintResponse } from '../../../../generated/nms/model/models';

export enum FeatureHintPlace {
  ReportTab = 'ReportTab',
  NewReportBtn = 'NewReportBtn',
  RoadGradeCheckingReport = 'RoadGradeCheckingReport',
  SurfaceGradeCheckingReport = 'SurfaceGradeCheckingReport',
  WaterFlowReport = 'WaterFlowReport',
  SidenavOverviewCardOptions = 'SidenavOverviewCardOptions',
  DownloadDXFBtn = 'DownloadDXFBtn',
  AnalyticsTab = 'AnalyticsTab',
  AnalyticsCardOptions = 'AnalyticsCardOptions',
  ZoomIntoBtn = 'ZoomIntoBtn',
  VolumeGridHeatmapReport = 'VolumeGridHeatmapReport',
  RotationAngleField = 'RotationAngleField',
  MainMenu = 'MainMenu',
  ProcessingHistoryBtn = 'ProcessingHistoryBtn',
  UsersAndPermissionsBtn = 'UsersAndPermissionsBtn',
  DownloadUsersAndPermissionsBtn = 'DownloadUsersAndPermissionsBtn',
  MeasurementsTab = 'MeasurementsTab',
  AnnotationsTab = 'AnnotationsTab',
  DesignsTab = 'DesignsTab',
  ModelEditsTab = 'ModelEditsTab',
  ActivitiesTab = 'ActivitiesTab',
  OverviewTab = 'OverviewTab'
}

export interface FeatureHintData extends GetNFIHintResponse {
  showOnHover: boolean;
  kept: boolean;
  place: FeatureHintPlace;
}

interface FeatureHintContent {
  title: string;
  description: string;
  moreInfoLink?: string;
}

export const featureHintsContents: { [place in FeatureHintPlace]?: FeatureHintContent } = {
  // Example:
  // [FeatureHintPlace.RoadGradeCheckingReport]: {
  //   title: $localize`:@@shared.featureHints.roadGradeCheckingReportTitle:Road Grade Checking report`,
  //   description: $localize`:@@shared.featureHints.roadGradeCheckingReportDescription:Compare surveyed points to your road design, including horizontal and vertical deviations along the centerline and sections`,
  //   moreInfoLink: 'https://support.datumate.com/portal/en/kb/articles/introducing-surface-and-road-grade-checking-reports-in-datubim'
  // }
  [FeatureHintPlace.WaterFlowReport]: {
    title: $localize`:@@shared.featureHints.waterFlowReportTitle:Water Flow Report`,
    description: $localize`:@@shared.featureHints.waterFlowReportDescription:Effortlessly visualize and verify water movement across your project for enhanced safety and efficiency`,
    moreInfoLink: 'https://support.datumate.com/portal/en/kb/articles/water-flow-report'
  }
} as const;

export interface FeatureHintsState extends EntityState<FeatureHintData, string> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'feature-hints', producerFn: produce, idKey: 'id' })
export class FeatureHintsStore extends EntityStore<FeatureHintsState> {
  constructor() {
    super();
  }

  dismissHintsByPlace(place: FeatureHintPlace) {
    this.remove(item => item.place === place);
  }

  keepHintsByPlace(place: FeatureHintPlace) {
    this.update(item => item.place === place, { kept: true });
  }

  disableHoverByPlace(place: FeatureHintPlace) {
    this.update(item => item.place === place, { showOnHover: false });
  }

  initiateState(newState: FeatureHintData[]) {
    this.set(newState);
  }
}
