/**
 * Datumate
 * API's for mile stone I
 *
 * OpenAPI spec version: 3.5.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface DesignErrorModel {
  code?: string;
  description?: string;
  type?: DesignErrorModel.TypeEnum;
}
export namespace DesignErrorModel {
  export type TypeEnum = 'WARNING' | 'ERROR';
  export const TypeEnum = {
    WARNING: 'WARNING' as TypeEnum,
    ERROR: 'ERROR' as TypeEnum
  };
}
