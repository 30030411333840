import { Location } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { EMPTY } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { LoginResponse } from '../../../generated/ums/model/loginResponse';
import { GetDomainProvidersResponse } from '../../../generated/ums/model/models';
import { IssueBannerType } from '../../shared/issue-banner/issue-banner.component';
import { LimitReachedPopupService } from '../../shared/limit-reached-popup/limit-reached-popup.service';
import { AnalyticsService } from '../../shared/services/analytics.service';
import { DeviceService } from '../../shared/services/device.service';
import { ServerError } from '../../shared/utils/backend-services';
import { isDefined } from '../../shared/utils/general';
import { AuthQuery } from '../state/auth.query';
import { AuthService } from '../state/auth.service';
import { DEFAULT_LOGIN_ERROR_MESSAGE, LogInStateEnum, jwtHelper } from '../state/auth.utils';

enum ViewType {
  LOGIN,
  ENTER_PASS,
  FORGOT_PASS,
  SET_NEW_PASS,
  ACCESS_DENIED
}

enum BannerPositions {
  SSO = 'SSO',
  FORM_BOTTOM = 'FORM_BOTTOM'
}

const FORM_TITLES = {
  [ViewType.LOGIN]: $localize`:@@auth.loginPage.formTitleSignIn:Sign in`,
  [ViewType.FORGOT_PASS]: $localize`:@@auth.loginPage.formTitleForgotPassword:Forgot password`,
  [ViewType.SET_NEW_PASS]: $localize`:@@auth.loginPage.formTitleSetNewPassword:Set new password`,
  [ViewType.ACCESS_DENIED]: $localize`:@@auth.loginPage.formTitleAccessDenied:Access denied`
};

interface LoginForm {
  email: FormControl<string>;
  password: FormControl<string>;
}

interface ChangePasswordForm {
  newPassword: FormControl<string>;
  verifyPassword: FormControl<string>;
}

interface SsoRedirectResponse {
  access_token: string;
  id_token: string;
  token_type: string;
  expires_in: string;
  state: string;
  parsedState?: SsoStateObject;
  error_description?: string;
}

interface SsoStateObject {
  email: string;
  redirectUrl?: string;
}

interface SetPasswordUserData {
  userId: string;
  token: string;
  userEmail?: string;
  redirectUrl?: string;
}

@UntilDestroy()
@Component({
  selector: 'login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {
  @ViewChild('loginButton', { read: ElementRef }) loginButton: ElementRef;
  @ViewChild('emailInputEl', { read: ElementRef }) emailInput: ElementRef;
  @ViewChild('passwordInputEl', { read: ElementRef }) passwordInput: ElementRef;
  ViewType = ViewType;
  BannerPositions = BannerPositions;
  FormTitles = FORM_TITLES;

  loginForm = new FormGroup<LoginForm>({
    email: new UntypedFormControl('', [
      Validators.required,
      Validators.email,
      Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$')
    ]),
    password: new UntypedFormControl('', [Validators.required])
  });
  changePasswordForm = new FormGroup<ChangePasswordForm>(
    {
      newPassword: new UntypedFormControl(null, [Validators.required]),
      verifyPassword: new UntypedFormControl(null, [Validators.required])
    },
    { validators: [this.checkPasswordsSame, this.checkPasswordPolicy] }
  );

  currentView: ViewType = ViewType.LOGIN;
  poweredByURL = environment.whitelabel.poweredByURLLoginPage;
  appName = environment.whitelabel.appName;

  loading = false;

  ssoProviders: GetDomainProvidersResponse[] = [];
  selectedProvider: GetDomainProvidersResponse;
  allowPasswordLogin = false;
  isNewPassword = false;
  showBackButton = false;
  bannerMessage = {
    show: false,
    type: '',
    caption: '',
    position: BannerPositions.FORM_BOTTOM
  };

  routeUserData: SetPasswordUserData;
  usernamePlaceholder: string;
  isPasswordVisible = false;

  constructor(
    private authQuery: AuthQuery,
    private authService: AuthService,
    private router: Router,
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private limitReachedPopup: LimitReachedPopupService,
    public device: DeviceService,
    private analyticsService: AnalyticsService
  ) {}

  ngOnInit() {
    const route = this.activatedRoute.snapshot;
    const routePath = route.routeConfig.path;

    // Take fragment from URL and remove it
    const fragment = route.fragment;
    this.location.replaceState(window.location.pathname, window.location.search);

    this.isNewPassword = routePath.includes('setNewUserPassword') || routePath.includes('changePassword');

    if (this.isNewPassword) {
      this.routeUserData = this.parseURLData(window.atob(route.paramMap.get('userData'))) as SetPasswordUserData;

      if (this.routeUserData.userEmail && routePath.includes('setNewUserPassword')) {
        this.showLoader(true);
        this.getUserSSOProviders(this.routeUserData.userEmail);
      } else {
        this.setViewState(ViewType.SET_NEW_PASS);
      }
    }

    const ssoData = this.parseURLData(fragment) as SsoRedirectResponse;
    ssoData.parsedState = ssoData.state && JSON.parse(window.atob(ssoData.state));
    if (ssoData.parsedState) {
      if (ssoData.access_token && ssoData.id_token) {
        this.showLoader(true);
        this.handleReturnFromRedirect(ssoData);
      } else {
        this.handleSSOAuthFailed(decodeURIComponent(ssoData.error_description).replaceAll('+', ' '), ssoData.parsedState?.email);
      }
    }

    this.initFormByView();
  }

  private parseURLData(data: string) {
    if (!data) {
      return {};
    }
    return data?.split('&').reduce((acc, item) => {
      const [key, value] = item.split('=');
      acc[key] = value;
      return acc;
    }, {});
  }

  private handleReturnFromRedirect(ssoData: SsoRedirectResponse) {
    const stateObj = ssoData.parsedState;
    const idTokenObj = jwtHelper.decodeToken(ssoData?.id_token);

    if (stateObj?.email.toLowerCase() !== idTokenObj?.email.toLowerCase()) {
      this.getUserSSOProviders(stateObj.email, true);
      this.showBannerMessage(
        'error',
        `DatuBIM single sign-on mismatch <a href="${environment.whitelabel.knowledgeBaseUrl}/articles/resolving-datubim-single-sign-on-mismatch-error" target="blank">more info...</a>`,
        BannerPositions.SSO
      );
      return;
    }

    this.authService.login({ accessToken: ssoData.access_token, email: stateObj.email }, true).subscribe({
      next: ({ userSession }) => this.handleLoginSuccess(userSession, stateObj.redirectUrl),
      error: err => this.handleSSOAuthFailed(err, stateObj.email)
    });
  }

  handleSSOAuthFailed(err: string | Error, userEmail: string) {
    const providerName =
      this.selectedProvider?.name ||
      JSON.parse(localStorage.getItem('LATEST_SSO_SELECTED'))?.name ||
      $localize`:@@auth.loginPage.provider:Provider`;
    console.error(`Failed to sign in with ${providerName}`, err);
    this.showBannerMessage(
      'error',
      $localize`:@@auth.loginPage.failedToSignInWith:Failed to sign in with ${providerName}`,
      BannerPositions.SSO
    );
    this.getUserSSOProviders(userEmail, true);
  }

  loginSubmit() {
    if (this.isActiveViewState(ViewType.LOGIN)) {
      return this.submitUserEmail();
    }

    if (!this.loginForm.valid) {
      const emailField = this.loginForm.get('email');
      const passwordField = this.loginForm.get('password');
      const formFieldErrorMsg =
        emailField.errors?.required || passwordField.errors?.required
          ? $localize`:@@auth.loginPage.beforeSigningInFillEmailAndPassword:Please fill your email and password before signing in`
          : $localize`:@@auth.loginPage.beforeSigningInFillValidEmail:Please fill your valid email address before signing in`;
      this.showBannerMessage('error', formFieldErrorMsg);
      return;
    }

    const { email, password } = this.loginForm.value;
    this.showLoader(true);
    const redirectUrl = this.routeUserData?.redirectUrl || this.activatedRoute.snapshot.queryParamMap.get('redirectUrl');
    this.authService.login({ email: this.usernamePlaceholder, password }).subscribe({
      next: ({ userSession }) => this.handleLoginSuccess(userSession, redirectUrl),
      error: err => this.handleLoginFailed(err)
    });
  }

  forgotPassSubmit() {
    if (!this.loginForm.valid) {
      const emailField = this.loginForm.get('email');
      if (emailField.errors?.required || emailField.errors?.email) {
        this.showBannerMessage('error', $localize`:@@auth.loginPage.pleaseFillYourValidEmail:Please fill your valid email`);
      }
      return;
    }
    const { email } = this.loginForm.value;
    this.showLoader(true);
    this.authService.forgotPasswordRequest({ email: email.trim() }).subscribe({
      next: () => {
        this.setViewState(ViewType.LOGIN);
        this.initFormByView();
        this.showBannerMessage(
          'info',
          $localize`:@@auth.loginPage.resetPasswordSentEmailSuccess:Reset password email was sent. Please check your inbox.`
        );
        this.showLoader(false);
      },
      error: response => {
        console.error('Error in forgot password request', response);

        this.setViewState(ViewType.LOGIN);
        this.initFormByView();
        this.showBannerMessage(
          'error',
          $localize`:@@auth.loginPage.resetPasswordSentEmailFailed:Reset password email has failed. Please contact support.`
        );
        this.showLoader(false);
      }
    });
  }

  changePasswordSubmit() {
    const userId = this.routeUserData.userId;
    const token = this.routeUserData.token;
    if (userId && token && this.changePasswordForm.valid) {
      const password = this.changePasswordForm.get('newPassword').value;
      this.authService.changePasswordRequest(userId, token, password).subscribe({
        next: () => {
          // Using `window.open` instead of `router.navigate` because window needs to refresh when changing password
          const redirectUrl = this.routeUserData.redirectUrl || '';
          const url = redirectUrl ? '/login?redirectUrl=' + redirectUrl : 'login';
          window.open(url, '_self');
        },
        error: response => {
          let msg = $localize`:@@auth.loginPage.errorChangingPassword:Error changing password`;
          if (response?.error?.code === ServerError.ForgotPasswordTokenNotMatch) {
            msg +
              '. ' +
              $localize`:@@auth.loginPage.pleaseUseLinkFormLatestEmail:Please use the link from the latest password change email you received`;
          }

          console.error(msg, response);
          this.showBannerMessage('error', msg);
        }
      });
    } else {
      this.showBannerMessage('error', $localize`:@@auth.loginPage.errorChangingPassword:Error changing password`);
    }
  }

  handleLoginSuccess(loginResponse: LoginResponse[], redirectUrl?: string) {
    // If all sessions are expired show popup to user, if only some - the user will login and see expired ones in switch account menu
    if (loginResponse?.every(session => session.logInState === LogInStateEnum.EXPIREDPACKAGE)) {
      this.showLoader(false);
      // Using `setTimeout` to make sure the view is refreshed after `showLoader(false)`
      setTimeout(() => {
        this.showExpiredPopup();
      }, 0);
      return;
    }

    // All sessions are not valid
    if (!isDefined(loginResponse) || loginResponse.every(session => session.logInState !== LogInStateEnum.SUCCESS)) {
      this.showLoader(false);
      const msg = DEFAULT_LOGIN_ERROR_MESSAGE;
      console.error(msg);
      this.showBannerMessage('error', msg);
      return;
    }

    if (redirectUrl) {
      this.router.navigateByUrl(redirectUrl, { replaceUrl: true });
    } else {
      const userRole = this.authQuery.getUserRole();
      const defaultRedirectUrl = this.authQuery.getDefaultUrl(userRole);
      this.router.navigateByUrl(defaultRedirectUrl, { replaceUrl: true });
    }
  }

  handleLoginFailed(response) {
    let msg = DEFAULT_LOGIN_ERROR_MESSAGE;
    if (response.status === 403 && response.error?.code === ServerError.LoginFail) {
      msg = $localize`:@@auth.loginPage.errorLoginIncorrect:Incorrect email or password`;
    } else if (response.status === 423 && response.error?.code === ServerError.LockedUser) {
      msg = $localize`:@@auth.loginPage.errorLoginUserLocked:Your user is locked due to too many failed login attempts. Please try again later.`;
    } else if (response.status === 403 && response.error?.code === ServerError.userMultiActiveSessions) {
      this.showAccessDenied();
    }

    console.error(msg, response);
    this.showLoader(false);
    this.showBannerMessage('error', msg);
  }

  private showExpiredPopup() {
    const boundingRect = this.loginButton.nativeElement.getBoundingClientRect();
    this.limitReachedPopup.open({
      position: {
        top: boundingRect.top - 10 + 'px',
        left: boundingRect.right + 20 + 'px'
      },
      direction: 'LEFT',
      title: $localize`:@@auth.loginPage.subscriptionExpiredTitle:Subscription expired`,
      emailSubject: 'Subscription renewal request',
      contactSupportButtonCaption: $localize`:@@auth.loginPage.contactUs:Contact us`,
      content: `${$localize`:@@auth.loginPage.subscriptionExpired:Your subscription has reached its expiry date.`}

      ${$localize`:@@auth.loginPage.contactForRenewSubscription:Please contact our sales department to renew your subscription.`}`
    });
  }

  private showAccessDenied() {
    this.setViewState(ViewType.ACCESS_DENIED);
    this.showBackButton = false;
  }

  hasForgotPasswordError(errorType: string) {
    const newPasswordField = this.changePasswordForm.get('newPassword');
    const verifyPasswordField = this.changePasswordForm.get('newPassword');
    if (!this.changePasswordForm.valid && newPasswordField?.touched && verifyPasswordField?.touched) {
      return this.changePasswordForm.errors?.[errorType] || false;
    }
    return false;
  }

  private checkPasswordsSame(group: FormGroup<ChangePasswordForm>) {
    const pass = group.get('newPassword').value;
    const confirmPass = group.get('verifyPassword').value;

    return !pass || !confirmPass || pass === confirmPass ? null : { notSame: true };
  }

  private checkPasswordPolicy(group: FormGroup<ChangePasswordForm>) {
    const pass = group.get('newPassword').value;
    const passPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d$@$!%*#?&]{8,}$/g;

    return !pass || passPattern.test(pass) ? null : { passPolicy: true };
  }

  initFormByView() {
    switch (this.currentView) {
      case ViewType.FORGOT_PASS:
        this.loginForm.removeControl('password');
        break;
      case ViewType.ENTER_PASS:
        this.loginForm.addControl('password', new FormControl('', Validators.required));
        break;

      case ViewType.LOGIN:
      case ViewType.SET_NEW_PASS:
      default:
        break;
    }

    this.loginForm?.get('email').valueChanges.subscribe(() => {
      // Handle email changes from browser's autofill in from password field
      if (this.isActiveViewState(ViewType.ENTER_PASS) && !this.loginForm.get('email').untouched) {
        this.getUserSSOProviders();
        this.hideBannerMessages();
      }
    });
  }

  setViewState(state: ViewType) {
    if (state === ViewType.LOGIN) {
      this.showBackButton = false;
      this.setPasswordVisibility(false);
    }
    if (state === ViewType.ENTER_PASS) {
      this.usernamePlaceholder = this.loginForm.get('email').value;
    }
    this.currentView = state;

    setTimeout(() => {
      if (state === ViewType.LOGIN) {
        this.emailInput.nativeElement.focus();
      } else if (state === ViewType.ENTER_PASS || state === ViewType.FORGOT_PASS) {
        this.passwordInput.nativeElement.focus();
      }
    });
  }

  isActiveViewState(state: ViewType) {
    return this.currentView === state;
  }

  stepBack() {
    this.hideBannerMessages();

    switch (this.currentView) {
      case ViewType.ENTER_PASS:
        this.setViewState(ViewType.LOGIN);
        break;
      case ViewType.FORGOT_PASS:
        this.setViewState(ViewType.ENTER_PASS);
        this.initFormByView();
        break;
      case ViewType.SET_NEW_PASS:
        this.showBackButton = false;
        this.setViewState(ViewType.ENTER_PASS);
        this.initFormByView();
        break;

      default:
        break;
    }
  }

  submitUserEmail() {
    this.hideBannerMessages();

    if (this.loginForm.get('email').invalid) {
      return this.showBannerMessage('error', $localize`:@@auth.loginPage.pleaseEnterValidEmailAddress:Please enter valid Email Address`);
    }

    if (this.isActiveViewState(ViewType.LOGIN)) {
      this.showLoader(true);
      return this.getUserSSOProviders();
    } else if (this.isActiveViewState(ViewType.FORGOT_PASS)) {
      return this.forgotPassSubmit();
    }
  }

  getUserSSOProviders(userEmail?: string, preventImmediateSSOLogin = false) {
    const email = this.loginForm?.get('email')?.value || userEmail;
    if (!email) {
      this.setViewState(ViewType.LOGIN);
      return;
    }

    const redirectUrl = this.routeUserData?.redirectUrl || this.activatedRoute.snapshot.queryParamMap.get('redirectUrl');
    this.authService
      .getUserSSOProviders(email)
      .pipe(
        tap(() => this.showLoader(true)),
        catchError(err => {
          console.error(`Failed to fetch domain providers: ${err.error?.message}`, err);
          return EMPTY;
        })
      )
      .subscribe({
        next: (domainProviders: GetDomainProvidersResponse[]) => {
          // Add redirect_uri and state field to provider URL
          const stateObj: SsoStateObject = { email, redirectUrl };
          const stateStr = window.btoa(JSON.stringify(stateObj));
          this.ssoProviders = domainProviders.map(provider => ({
            ...provider,
            url: provider.url + `&redirect_uri=${location.origin}/login&state=${stateStr}`
          }));

          const lastSelectedProvider = JSON.parse(localStorage.getItem('LATEST_SSO_SELECTED'));
          this.selectedProvider = lastSelectedProvider && this.ssoProviders.find(e => e.id === lastSelectedProvider.id);
        },
        complete: () => {
          this.allowPasswordLogin = !isDefined(this.ssoProviders) || this.ssoProviders.every(provider => provider.allowPasswordLogin);
          if (!preventImmediateSSOLogin && isDefined(this.ssoProviders) && this.ssoProviders.length === 1 && !this.allowPasswordLogin) {
            // Use only provider to login immediately
            this.openSSOUrl(this.ssoProviders[0]);
          } else {
            this.showLoader(false);
            this.setViewState(ViewType.ENTER_PASS);
            this.showBackButton = !this.isNewPassword;
            if (!this.loginForm.get('email').value) {
              this.usernamePlaceholder = email;
            }
          }
        }
      });
  }

  openSSOUrl(ssoProvider: GetDomainProvidersResponse) {
    this.analyticsService.tryToLoginBySSO(ssoProvider.name);
    localStorage.setItem('LATEST_SSO_SELECTED', JSON.stringify({ id: ssoProvider.id, name: ssoProvider.name }));
    window.open(ssoProvider.url, '_self');
  }

  showForgotPassword() {
    this.setViewState(ViewType.FORGOT_PASS);
    this.initFormByView();
  }

  moveToSetNewPassword() {
    this.showBackButton = true;
    this.setViewState(ViewType.SET_NEW_PASS);
    this.initFormByView();
  }

  hideBannerMessages() {
    this.bannerMessage.show = false;
  }

  showBannerMessage(type: IssueBannerType, caption: string, position: BannerPositions = BannerPositions.FORM_BOTTOM) {
    this.bannerMessage = { show: true, type, caption, position };
  }

  showLoader(show: boolean) {
    this.loading = show;
  }

  setPasswordVisibility(isVisible: boolean) {
    if (!this.allowPasswordLogin) {
      return;
    }

    this.isPasswordVisible = isVisible;
    const inputEl = this.passwordInput.nativeElement;
    inputEl.type = isVisible ? 'text' : 'password';
  }

  contactUs() {
    window.open(`mailto:${environment.whitelabel.supportEmail}?subject=Login access denied`, '_self');
  }
}
