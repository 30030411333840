import { FeatureCollection } from '@turf/turf';
import { AcEntity, Cartesian3 } from 'angular-cesium';
import { environment } from '../../../../environments/environment';
import { BaseSurface } from '../../../../generated/mms/model/baseSurface';
import { CoordinateModel } from '../../../../generated/mms/model/coordinateModel';
import { GetAnalyticResponse } from '../../../../generated/mms/model/getAnalyticResponse';
import { GetAnnotationNoteResponse } from '../../../../generated/mms/model/getAnnotationNoteResponse';
import { GetAnnotationResponse } from '../../../../generated/mms/model/getAnnotationResponse';
import { GetAttachmentResponse } from '../../../../generated/mms/model/getAttachmentResponse';
import { GetCategoryResponse } from '../../../../generated/mms/model/getCategoryResponse';
import { GetCreatedByResponse } from '../../../../generated/mms/model/getCreatedByResponse';
import { GetGroupResponse } from '../../../../generated/mms/model/getGroupResponse';
import { GetLayerResponse } from '../../../../generated/mms/model/getLayerResponse';
import { GetMeasurementResponse } from '../../../../generated/mms/model/getMeasurementResponse';
import { GetModelEditResponse } from '../../../../generated/mms/model/getModelEditResponse';
import { DrawingStyleProps, GetDataResponse, GetDrawingResponse } from '../../../../generated/mms/model/models';
import { ResourceLinkType } from '../../../shared/resource-links/resource-links.model';
import { UnitsEnum } from '../../../shared/utils/unit-conversion';
import { PolygonPolylineEditorObservable } from '../../site-map/services/editor-utils.service';

export const DEFAULT_GROUP_LAYER_ID = '';
export const DEFAULT_LAYER_COLOR = environment.whitelabel.primaryColor;
export const LATEST_LAYER_SELECTED_STORAGE_KEY = 'LATEST_LAYER_SELECTED';
export const LATEST_GROUP_SELECTED_STORAGE_KEY = 'LATEST_GROUP_SELECTED';
export const DEFAULT_LAYER = { id: DEFAULT_GROUP_LAYER_ID, name: 'Default Layer', color: DEFAULT_LAYER_COLOR };

export interface DataValue {
  key: string;
  field: string;
  value: number;
  units: UnitsEnum;
  unitExp: number;
  tooltip?: string;
}

// task or design id and type
export interface TaskOrDesignInfo {
  id: string;
  type: 'TASK' | 'DESIGN';
}

export interface TaskOrDesignValues extends TaskOrDesignInfo {
  values: DataValue[];
}

export import SourceModel = GetMeasurementResponse.SourceModelEnum;

export interface BaseEntity {
  selected?: boolean;
  pinned?: boolean;
  inEdit?: boolean;
  calcResult?: TaskOrDesignValues[];
  geoJson?: FeatureCollection;
  positions: Cartesian3[];
  coordinates?: CoordinateModel[];
  sourceModel: SourceModel;
  showMapVizLayer?: boolean;
}

export enum CalcStatus {
  UNKNOWN,
  SUCCESS,
  SURFACE_SAMPLING_ERROR,
  MODEL_SAMPLING_ERROR,
  SURFACE_BOUNDS_ERROR
}

export type AnalyticType = GetAnalyticResponse.TypeEnum;
export const AnalyticType = GetAnalyticResponse.TypeEnum;
export class Analytic implements GetAnalyticResponse, BaseEntity, AcEntity {
  id?: string;
  groupId?: string;
  layerId?: string;
  name: string;
  type: AnalyticType;
  selected?: boolean;
  pinned?: boolean;
  inEdit?: boolean;
  calcStatus?: CalcStatus;
  calcResult?: TaskOrDesignValues[];
  geoJson?: FeatureCollection;
  dataVersion?: number;
  dataUrl?: string;
  positions: Cartesian3[];
  coordinates?: CoordinateModel[];
  baseSurface?: BaseSurface;
  sourceModel: SourceModel;
  createdBy?: string;
  creationTime?: Date;
  lastModifiedTime?: Date;
  showMapVizLayer?: boolean;
}

export type MeasurementType = GetMeasurementResponse.TypeEnum;
export const MeasurementType = GetMeasurementResponse.TypeEnum;
export class Measurement implements GetMeasurementResponse, BaseEntity, AcEntity {
  id?: string;
  taskId?: string;
  groupId?: string;
  layerId?: string;
  name: string;
  type: MeasurementType;
  selected?: boolean;
  pinned?: boolean;
  inEdit?: boolean;
  calcStatus?: CalcStatus;
  calcResult?: TaskOrDesignValues[];
  geoJson?: FeatureCollection;
  dataVersion?: number;
  dataUrl?: string;
  positions: Cartesian3[];
  coordinates?: CoordinateModel[];
  baseSurface?: BaseSurface;
  sourceModel: SourceModel;
  createdBy?: string;
  creationTime?: Date;
  lastModifiedTime?: Date;
  showMapVizLayer?: boolean;
}

export interface AnnotationFile extends GetAttachmentResponse {
  markedForSave?: boolean;
  markedForDelete?: boolean;
  size?: number;
  file?: File;
  isEditable?: boolean;
}

export interface AnnotationNote extends GetAnnotationNoteResponse {
  markedForSave?: boolean;
  markedForDelete?: boolean;
}

export type AnnotationData = GetDataResponse;

export type DrawingType = GetDrawingResponse.TypeEnum;
export const DrawingType = GetDrawingResponse.TypeEnum;

export interface Drawing extends GetDrawingResponse {
  positions?: Cartesian3[];
  coordinates?: CoordinateModel[];
  /** false by permissions */
  isEditable?: boolean;
  /** true from click on create new drawing to ending the creation by double click */
  inCreation?: boolean;
  /** true for new new drawing until clicking on Done / Cansel */
  isTemp?: boolean;
  /** true for new measurements until saved in DB (has temporary id with NEW_ANNOTATION_DRAWING_ID_PREFIX) */
  isNew?: boolean;
  /** true when editing new or existing drawing until saved in DB */
  markedForSave?: boolean;
  /** true for deleted drawing until updated in DB (hidden for visualization) */
  markedForDelete?: boolean;
}

export interface DrawingEditor {
  drawingId: string;
  editorId: string;
  editor$: PolygonPolylineEditorObservable;
}

export interface StoreDrawingStyleProps extends DrawingStyleProps {
  arrowLineWidthPx?: number;
}

export import AnnotationStatus = GetAnnotationResponse.StatusEnum;
export import AnnotationPriority = GetAnnotationResponse.PriorityEnum;
export interface AnnotationCategory extends GetCategoryResponse {}

export type AnnotationType = 'ANNOTATION';
export const AnnotationType = {
  ANNOTATION: 'ANNOTATION' as AnnotationType
};

export class Annotation implements GetAnnotationResponse, BaseEntity, AcEntity {
  id?: string;
  groupId?: string;
  layerId?: string;
  name: string;
  type: AnnotationType;
  selected?: boolean;
  pinned?: boolean;
  inEdit?: boolean;
  positions: Cartesian3[];
  coordinates?: CoordinateModel[];
  date?: Date;
  description?: string;
  status?: AnnotationStatus;
  notes?: AnnotationNote[];
  attachments?: AnnotationFile[];
  data?: AnnotationData[];
  createdBy?: GetCreatedByResponse;
  creationTime?: Date;
  lastModifiedTime?: Date;
  category?: AnnotationCategory;
  assignee?: string;
  notifiedUsers?: string[];
  priority?: AnnotationPriority;
  drawings?: Drawing[];
  hiddenPosition?: boolean;
  isEditable?: boolean;

  // Not in use, here for MapEntity type to work with annotations
  calcStatus?: CalcStatus;
  calcResult?: TaskOrDesignValues[];
  geoJson?: FeatureCollection;
  dataVersion?: number;
  dataUrl?: string;
  baseSurface?: BaseSurface;
  sourceModel: SourceModel;
  showMapVizLayer?: boolean;
}

export import ModelEditType = GetModelEditResponse.TypeEnum;

export class ModelEdit implements GetModelEditResponse, BaseEntity, AcEntity {
  name?: string;
  id?: string;
  selected?: boolean;
  pinned?: boolean;
  inEdit?: boolean;
  dataVersion?: number;
  calcStatus?: CalcStatus;
  calcResult?: TaskOrDesignValues[];
  positions: Cartesian3[];
  coordinates?: CoordinateModel[];
  sourceModel: SourceModel;
  type: ModelEditType;
  positionsElevation?: TaskPositionsElevation;
  createdBy?: string;
  creationTime?: Date;
  lastModifiedTime?: Date;

  // Not in use, here for MapEntity type to work with model edits
  groupId?: string;
  layerId?: string;
  geoJson?: FeatureCollection;
  dataUrl?: string;
  baseSurface?: BaseSurface;
  showMapVizLayer?: boolean;
}

export interface TaskPositionsElevation {
  [taskId: string]: number[];
}

export type MapEntity = Analytic | Measurement | Annotation | ModelEdit;

export import BaseSurfaceType = BaseSurface.TypeEnum;

export interface BaseSurfaceOption extends BaseSurface {
  name: string;
}

export enum PolygonType {
  POLYGON_DELTA_ELEVATION = 'POLYGON_DELTA_ELEVATION',
  POLYGON_DELTA_VOLUME = 'POLYGON_DELTA_VOLUME',
  AREA = 'AREA',
  VOLUME = 'VOLUME',
  ANGLE = 'ANGLE'
}

export enum LineType {
  CROSS_SECTION_PROGRESS = 'CROSS_SECTION_PROGRESS',
  DISTANCE = 'DISTANCE',
  CROSS_SECTION = 'CROSS_SECTION'
}

export enum PointType {
  POINT_DELTA_ELEVATION = 'POINT_DELTA_ELEVATION',
  POINT = 'POINT',
  ANNOTATION = 'ANNOTATION'
}

export class CrossSectionPoint extends AcEntity {
  id: string;
  position: Cartesian3;
  show?: boolean;
  color?: any;
}

export type EntityType = AnalyticType | MeasurementType | AnnotationType | ModelEditType;
export const EntityType = {
  ...AnalyticType,
  ...MeasurementType,
  ...AnnotationType,
  ...ModelEditType
};

export const EntityTypeWithBaseSurface = {
  [PolygonType.POLYGON_DELTA_ELEVATION]: PolygonType.POLYGON_DELTA_ELEVATION,
  [PolygonType.VOLUME]: PolygonType.VOLUME,
  [PolygonType.POLYGON_DELTA_VOLUME]: PolygonType.POLYGON_DELTA_VOLUME
};

export const EntityTypeWithGeoJson = {
  [PolygonType.POLYGON_DELTA_ELEVATION]: PolygonType.POLYGON_DELTA_ELEVATION,
  [PolygonType.VOLUME]: PolygonType.VOLUME,
  [PolygonType.POLYGON_DELTA_VOLUME]: PolygonType.POLYGON_DELTA_VOLUME,
  [LineType.CROSS_SECTION]: LineType.CROSS_SECTION,
  [LineType.CROSS_SECTION_PROGRESS]: LineType.CROSS_SECTION_PROGRESS
};

export const EntityTypeWithLinkedResources = {
  [PointType.ANNOTATION]: PointType.ANNOTATION
};

export function getResourceTypeFromEntityType(entityType: EntityType) {
  if (entityType in AnalyticType) {
    return ResourceLinkType.ANALYTIC;
  } else if (entityType in MeasurementType) {
    return ResourceLinkType.MEASUREMENT;
  } else if (entityType in AnnotationType) {
    return ResourceLinkType.ANNOTATION;
  } else if (entityType in ModelEditType) {
    return ResourceLinkType.MODELEDIT;
  }
}

export const CrossSectionEntityType = {
  [MeasurementType.CROSS_SECTION]: MeasurementType.CROSS_SECTION,
  [AnalyticType.CROSS_SECTION_PROGRESS]: AnalyticType.CROSS_SECTION_PROGRESS
};

export type Layer = GetLayerResponse;
export type Group = GetGroupResponse;
